import Vue from 'vue';
import VueRouter from 'vue-router';

const Dashboard = () => import('@/components/Dashboard/Dashboard')
const Account = () => import('@/components/Account/Account')
const Admin = () => import('@/components/Admin/Admin')
const OrderPlan = () => import('@/components/PlaceOrder/OrderPlan')
const OrderPickupOrDelivery = () => import('@/components/PlaceOrder/OrderPickupOrDelivery')
const OrderCheckout = () => import('@/components/PlaceOrder/OrderCheckout')
const OrderCheckoutScreen = () => import('@/components/PlaceOrder/OrderCheckoutScreen')
const OrderCustomize = () => import('@/components/Orders/OrderCustomize')
const Guide = () => import('@/components/InitialGuide/Guide')

import Auth from '@/components/Auth/Auth';
import NotFoundComponent from '@/components/Globals/NotFoundComponent';
import ComingSoon from '@/components/Globals/ComingSoon';
import Maintenance from '@/components/Globals/Maintenance';
import Metrics from '@/components/Metrics/Metrics';
import API from '@/components/Globals/API';

import {requires_token} from './guards';
import {account_children} from './children/account/index';
import {admin_children} from './children/admin/index';
import {auth_children} from './children/auth/index';
import {metrics_children} from './children/metrics/index';


Vue.use(VueRouter);

export default new VueRouter({
	mode: 'history',
	routes: [
		{
			path: '*',
			component: NotFoundComponent
		},
		{
			path: '/',
			name: 'Home',
			component: Dashboard,
			beforeEnter: requires_token,
		},
		{
			path: '/dashboard',
			name: 'Dashboard',
			component: Dashboard,
			beforeEnter: requires_token,
		},
		{
			path: '/account',
			component: Account,
			beforeEnter: requires_token,
			children: account_children
		},
		{
			path: '/admin',
			component: Admin,
			beforeEnter: requires_token,
			children: admin_children
		},
		{
			path: '/authenticate',
			name: 'Auth',
			component: Auth,
			children: auth_children
		},
		{
			path: '/help-find-a-plan',
			name: 'Guide',
			component: Guide
		},
		{
			path: '/orders/choose-your-plan',
			name: 'OrderPlan',
			component: OrderPlan
		},
		{
			path: '/orders/pickup-or-delivery',
			name: 'OrderPickupOrDelivery',
			component: OrderPickupOrDelivery
		},
		{
			path: '/orders/submit-order',
			name: 'OrderCheckout',
			component: OrderCheckout
		},
		{
			path: '/orders-receipt',
			name: 'OrderCheckoutScreen',
			component: OrderCheckoutScreen,
			beforeEnter: requires_token,
		},
		{
			path: '/orders/customize/:orderItemId',
			name: 'OrderCustomize',
			component: OrderCustomize,
			beforeEnter: requires_token,
		},
		{
			path: '/orders-receipt/printable',
			name: 'orders-receipt',
			component: OrderCheckoutScreen,
			beforeEnter: requires_token,
		},
		{
			path: '/coming-soon',
			name: 'ComingSoon',
			component: ComingSoon
		},
		{
			path: '/maintenance',
			name: 'Maintenance',
			component: Maintenance
		},
		{
			path: '/metrics',
			name: 'Metrics',
			component: Metrics,
			children: metrics_children
		},
		{
			path: '/documentation',
			name: 'API',
			component: API,
		}
	]
});
