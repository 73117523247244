export const get_distributors = (state) => {
	return state.distributors.all || [];
};

export const get_distributor = (state) => (id) => {
	return state.distributors.byId[`${id}`];
};

export const get_tax_rates = (state) => {
	return state.distributors.taxRates;
};
