export const set_notifications = (state, notifications) => {
	state.notifications = notifications;
};

export const set_notification = (state, notification) => {
	let trimmed = state.notifications.filter(element => element.id !== notification.id);
	trimmed.push(notification);
	trimmed.sort((a, b) => {
		return a.name > b.name;
	});
	state.notifications = trimmed;
};
export const delete_notification = (state, notification) => {
	state.notifications = state.notifications.filter(element => element.id !== notification.id);
};
