import {clear_invalid_token} from './helper';

let base_url = process.env.VUE_APP_API_URL_BASE;

export const get = (params) => {
	params.commit('fetching_acquire');
	let endpoint = base_url + params.endpoint;
	return new Promise((resolve, reject) => {
		fetch(endpoint, {
			method: 'GET',
			headers: new Headers({
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + params.token
			})
		}).then(response => {
			if (response.status === 401) {
				clear_invalid_token();
			} else {
				response.json().then(data => {
					params.commit('fetching_release');
					resolve(data);
				}).catch(error => {
					params.commit('fetching_release');
					reject(error);
				});
			}
		});
	});
};

export const post = (params) => {
	params.commit('fetching_acquire');
	let endpoint = base_url + params.endpoint;
	return new Promise((resolve, reject) => {
		fetch(endpoint, {
			method: 'POST',
			body: JSON.stringify(params.body),
			headers: new Headers({
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + params.token
			})
		}).then(response => {
			if (response.status === 401) {
				clear_invalid_token();
			} else {
				response.json().then(data => {
					params.commit('fetching_release');
					resolve(data);
				}).catch(error => {
					params.commit('fetching_release');
					reject(error);
				});
			}
		});
	});
};

export const uploadRawApi = (params) => {
	params.commit('fetching_acquire');
	let endpoint = base_url + params.endpoint;
	return new Promise((resolve, reject) => {
		fetch(endpoint, {
			method: 'POST',
			body: params.body,
			headers: new Headers({
				'Authorization': 'Bearer ' + params.token
			})
		}).then(response => {
			if (response.status === 401) {
				clear_invalid_token();
			} else {
				response.json().then(data => {
					params.commit('fetching_release');
					resolve(data);
				}).catch(error => {
					params.commit('fetching_release');
					reject(error);
				});
			}
		});
	});
};

export const update = (params) => {
	params.commit('fetching_acquire');
	let endpoint = base_url + params.endpoint;
	return new Promise((resolve, reject) => {
		fetch(endpoint, {
			method: 'PUT',
			body: JSON.stringify(params.body),
			headers: new Headers({
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + params.token
			})
		}).then(response => {
			if (response.status === 401) {
				clear_invalid_token();
			} else {
				response.json().then(data => {
					params.commit('fetching_release');
					resolve(data);
				}).catch(error => {
					params.commit('fetching_release');
					reject(error);
				});
			}
		});
	});
};

export const destroy = (params) => {
	params.commit('fetching_acquire');
	let endpoint = base_url + params.endpoint;
	return new Promise((resolve, reject) => {
		fetch(endpoint, {
			method: 'DELETE',
			body: JSON.stringify(params.body),
			headers: new Headers({
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + params.token
			})
		}).then(response => {
			if (response.status === 401) {
				clear_invalid_token();
			} else {
				response.json().then(data => {
					params.commit('fetching_release');
					resolve(data);
				}).catch(error => {
					params.commit('fetching_release');
					reject(error);
				});
			}
		});
	});
};


export const patch = (params) => {
	params.commit('fetching_acquire');
	let endpoint = base_url + params.endpoint;
	return new Promise((resolve, reject) => {
		fetch(endpoint, {
			method: 'PATCH',
			body: JSON.stringify(params.body),
			headers: new Headers({
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + params.token
			})
		}).then(response => {
			if (response.status === 401) {
				clear_invalid_token();
			} else {
				response.json().then(data => {
					params.commit('fetching_release');
					resolve(data);
				}).catch(error => {
					params.commit('fetching_release');
					reject(error);
				});
			}
		});
	});
};
