export const requires_token = (to, from, next) => {
	try {
		if (localStorage.getItem('user')) {
			let user = JSON.parse(localStorage.getItem('user'));
			if (user == null || user.exp < Date.now()) {
				next('/authenticate/welcome');
			} else {
				next();
			}
		} else {
			next('/authenticate/welcome');
		}
	} catch (error) {
		next('/authenticate/welcome');
	}
};
