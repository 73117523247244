export const set_distributors = (state, distributors) => {
	state.distributors.all = (distributors || []).sort((a, b) => {
		return a.name > b.name;
	});
	let byId = {};
	state.distributors.all.forEach(distributor => {
		byId[`${distributor.id}`] = distributor;
	});
	state.distributors.byId = byId;
};

export const set_distributor = (state, distributor) => {
	let trimmed = state.distributors.all.filter(element => element.id !== distributor.id);
	trimmed.push(distributor);
	trimmed.sort((a, b) => {
		return a.name > b.name;
	});
	state.distributors.all = trimmed;
	state.distributors.byId[`${distributor.id}`] = distributor;
};


export const set_tax_rates = (state, taxRates) => {
	state.distributors.taxRates = taxRates;
};
