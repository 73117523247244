import $ from 'jquery'

export const showAlert = (payload) => {
	let {alertClass, alertHeader, alertMessage} = payload;
	$('#alertHeader').text(alertHeader);
	$('#alertMessage').text(alertMessage);
	$('#alertWidget').addClass(alertClass).show('slow');
	setTimeout(() => {
		$('#alertWidget').hide('slow');
	}, 3000);
};


export const unlock_ui = ({commit}) => {
	commit('unlock_ui');
};


