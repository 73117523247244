import { render, staticRenderFns } from "./AuthWelcome.vue?vue&type=template&id=eee7dcc0"
import script from "./AuthWelcome.vue?vue&type=script&lang=js"
export * from "./AuthWelcome.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports