import * as Api from '../api.js';

export const get_order = ({commit, getters}, orderId) => {
	return Api.get({
		commit: commit,
		endpoint: `users/${getters.get_user_id}/orders/${orderId}`,
		token: getters.token
	});
};

export const charge_order = ({commit, getters}, {userId, orderId, chargeParams}) => {
	return Api.post({
		commit: commit,
		endpoint: `payments/orders/${userId}/${orderId}/charge`,
		token: getters.token,
		body: chargeParams
	});
};

export const refund_order = ({commit, getters}, {userId, orderId, refundData}) => {
	return Api.post({
		commit: commit,
		endpoint: `payments/orders/${userId}/${orderId}/refund`,
		token: getters.token,
		body: refundData
	});
};

export const cancel_order = ({commit, getters}, {userId, orderId, itemId, refundData, reassign}) => {
	return Api.post({
		commit: commit,
		endpoint: `payments/orders/${userId}/${orderId}${itemId ? '/' + itemId : ''}${reassign ? '?reassign=1' : ''}`,
		token: getters.token,
		body: refundData
	});
};

export const reassign_order = ({commit, getters}, {userId, orderId}) => {
	return Api.post({
		commit: commit,
		endpoint: `admin/orders/${orderId}/reassign/${userId}`,
		token: getters.token
	});
};

export const delete_order = ({commit, getters}, {userId, orderId}) => {
	return Api.destroy({
		commit: commit,
		endpoint: `payments/orders/${userId}/${orderId}`,
		token: getters.token
	});
};

export const get_object_logs = ({commit, getters}, {table, key}) => {
	return Api.get({
		commit: commit,
		endpoint: `metrics/logs/${table}/${key}`,
		token: getters.token
	});
};

