<template>

	<div class="row justify-content-center px-lg-5">
		<div class="col-12 col-md-10">
			<div class="row justify-content-center">
				<div class="col-12 text-center">
					<h1>Let's get started!</h1>
					<messages v-if="error" type="error" :message="error" icon="exclamation-circle"/>
				</div>
			</div>
			<sign-up-form @finished="finished"/>
			<div class="row justify-content-between px-5 mt-3 mb-5 pb-4">
				<router-link class="" to="/authenticate/welcome">Back</router-link>
				<router-link to="/authenticate/reset-password-request" class="link">Forgot Password?</router-link>
			</div>
		</div>
	</div>

</template>

<script>
	import SignUpForm from '../Globals/SignUpForm';
	import Messages from '../Globals/Messages';

	export default {
		components: {
			Messages,
			SignUpForm
		},
		name: 'auth-sign-up',
		data() {
			return {
				error: ''
			};
		},
		methods: {
			finished() {
				this.$router.push('/dashboard');
			}
		},
		mounted() {
			this.$eventHub.$on('show-error', error => {
				this.error = error;
			});
		}
	};
</script>
