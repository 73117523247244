export const toggle_loader = (state) => {
	if (state.ui.loading === false) {
		state.ui.loading = true;
	} else {
		state.ui.loading = false;
	}
};

export const show_sub_menu = (state) => {
	state.ui.sub_menu = true;
};

export const hide_sub_menu = (state) => {
	state.ui.sub_menu = false;
};

export const loader_acquire = (state) => {
	state.ui.loader_semaphore += 1;
};

export const loader_release = (state) => {
	state.ui.loader_semaphore = Math.max(0, state.ui.loader_semaphore - 1);
};

export const set_loading_modal = (state, payload) => {
	state.ui.loading_modal_title = payload.title;
	state.ui.loading_modal_message = payload.message;
	state.ui.loading = true;
};

export const hide_loading_modal = (state) => {
	state.ui.loading = false;
	state.ui.loading_modal_title = '';
	state.ui.loading_modal_message = '';
};

export const set_view_label = (state, view) => {
	state.ui.current_view = view;
};

export const set_account_view = (state, view) => {
	state.ui.account_view = view;
};

export const unlock_ui = (state) => {
	sessionStorage.setItem('ui_locked', 'false');
	state.ui.locked = false;
};

export const fetching_acquire = (state) => {
	state.ui.fetching_semaphore += 1;
};

export const fetching_release = (state) => {
	state.ui.fetching_semaphore = Math.max(0, state.ui.fetching_semaphore - 1);
};


export const set_site_status = (state, status) => {
	state.ui.site_status = status;
};

export const set_nutrition_toggle = (state, status) => {
	state.ui.nutrition_facts_toggle = status;
};