<template>
	<div>

	</div>
</template>

<script>

	export default {
		name: 'auth-log-out',
		computed: {
			user() {
				return this.$store.getters.user;
			}
		},
		methods: {
			log_out() {
				this.$store.dispatch('log_out', this)
				.then( () => {
					this.$router.push('/authenticate/welcome');
				});
			}
		},
		mounted() {
			this.log_out();
		}
	};

</script>

<style scoped lang="scss">
	@import '../../styles/scss/colors';

	.icon {
		color: $grey;
		width: 40px;
	}

	/* Change the white to any color ;) */
	input:-webkit-autofill {
		-webkit-box-shadow: 0 0 0 30px white inset;
	}

	.link {
		color: $purple !important;
		font-weight: bold;
		&:hover {
			cursor: pointer;
		}
	}

</style>
