<template>
	<div>
		<div :class="'d-flex justify-content-between messages ' + type">
			<p class="capitalize">{{type}}:</p>
			<p class="capitalize">{{message}}</p>
			<p>
				<icon fill="currentColor" :name="icon" class=""></icon>
			</p>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['type', 'message', 'icon'],
		data() {
			return {};
		},
		mounted() {
		},
		methods: {}
	};
</script>

<style lang="scss" scoped>
	@import '../../styles/scss/colors';
	@import '../../styles/scss/fonts';

	.messages {
		text-align: center;
		background: $grey;
		border-radius: 5px;
		margin-top: 25px;
		margin-bottom: 25px;
		padding-top: 15px;
		padding-bottom: 0px;
		padding-left: 25px;
		padding-right: 25px;
		p {
			font-family: $fakt-medium;
			font-weight: 200;
			color: $grey;
		}
	}

	.message {
		background: $status100;
		border: 1px solid $blue;
		p {
			color: $blue;
		}
	}

	.success {
		background: $status200;
		border: 1px solid $green;
		p {
			color: $green;
		}
	}

	.warning {
		background: $status300;
		border: 1px solid $orange;
		p {
			color: $orange;
		}
	}

	.error {
		background: $status400;
		border: 1px solid $red;
		p {
			color: $red;
		}
	}

	.capitalize {
		text-transform: capitalize;
	}

	.times {
		color: $grey;
		&:hover {
			cursor: pointer;
		}
	}

	.out {
		transform: translateX(2000px);
		transition: transform .5s ease-in;
	}
</style>
