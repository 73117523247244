import * as Api from '../api';

export const get_meals = ({commit, getters}, {body}) => {
	return Api.post({
		commit: commit,
		endpoint: `meals`,
		token: getters.token,
		body: body
	})
	.then(result => {
		if (result.error) {
			// TODO: Handle Error
		} else {
			commit('set_meals', result.meals);
		}
		return result;
	})
	.catch( () => {
		// todo: handle error
	});
};

export const get_meal = ({commit, getters}, {mealId}) => {
	return Api.get({
		commit: commit,
		endpoint: `meals/${mealId}`,
		token: getters.token
	})
	.then(result => {
		if (result.error) {
			// TODO: Handle Error
		} else {
			commit('set_meal', result.meal);
		}
		return result;
	})
	.catch( () => {
		// todo: handle error
	});
};

export const get_meal_plans = ({commit}) => {
	return Api.get({
		commit: commit,
		endpoint: `meals/plans`
	})
	.then(result => {
		if (result.error) {
			// todo: handle error
		} else {
			commit('set_meal_plans', result.mealPlans);
		}
		return result;
	})
	.catch( () => {
		// todo: handle error
	});
};

export const get_meal_plan_infos = ({commit}) => {
	return Api.get({
		commit: commit,
		endpoint: `meals/plan-infos`
	})
	.then(result => {
		if (result.error) {
			// TODO: Handle Error
		} else {
			commit('set_meal_plan_infos', result.mealPlanInfos);
		}
		return result;
	})
	.catch( () => {
		// todo: handle error
	});
};

export const get_meal_plans_meals = ({commit, getters}) => {
	return Api.get({
		commit: commit,
		endpoint: `meals/plans/meals`,
		token: getters.token
	})
	.then(result => {
		if (result.error) {
			// TODO: Handle Error
		} else {
			commit('set_meal_plans_meals', result.plansMeals);
		}
		return result;
	})
	.catch( () => {
		// todo: handle error
	});
};

export const save_meal = ({commit, getters}, {meal}) => {
	return Api.post({
		commit: commit,
		endpoint: `meals/${meal.id}`,
		token: getters.token,
		body: meal
	})
	.then(result => {
		if (result.error) {
			// TODO: Handle Error
		} else {
			commit('set_meal', result.meal);
		}
		return result;
	})
	.catch( () => {
		// todo: handle error
	});
};
export const get_plan_meals = ({commit, getters}, {mealPlanId, year, week}) => {
	return Api.get({
		commit: commit,
		endpoint: `meals/plans/${mealPlanId}/${year}/${week}`,
		token: getters.token
	});
};

export const save_meal_plan = ({commit, getters}, {mealPlan}) => {
	return Api.post({
		commit: commit,
		endpoint: `meals/plans/${mealPlan.id}`,
		token: getters.token,
		body: mealPlan
	})
	.then(result => {
		if (result.error) {
			// TODO: Handle Error
		} else {
			//commit('save_meal_plan', result.mealPlan)
		}
		return result;
	})
	.catch( () => {
		// todo: handle error
	});
};

export const save_meal_plan_info = ({commit, getters}, {mealPlanInfo}) => {
	return Api.post({
		commit: commit,
		endpoint: `meals/plan-infos/${mealPlanInfo.id}`,
		token: getters.token,
		body: mealPlanInfo
	})
	.then(result => {
		if (result.error) {
			// TODO: Handle Error
		} else {
			//commit('save_meal_plan_info', result.mealPlanInfo)
		}
		return result;
	})
	.catch( () => {
		// todo: handle error
	});
};

export const get_order_item_subs = ({commit, getters}, payload) => {
	const {user_id, order_id, item_id, finished} = payload;
	return Api.get({
		commit: commit,
		endpoint: `users/${user_id}/orders/${order_id}/items/${item_id}/subs`,
		token: getters.token
	})
	.then(response => {
		finished(response.subs);
	})
	.catch( () => {
		// todo: handle error
	});
};

export const submit_swap = ({commit, dispatch, getters}, payload) => {
	const {userId, orderId, itemId, mealTime, mealOutId, mealInId, doPatch = true} = payload;
	return Api.post({
		commit: commit,
		endpoint: `users/${userId}/orders/${orderId}/items/${itemId}/swap/${mealTime}/${mealOutId}/${mealInId}`,
		token: getters.token,
		body: {}
	})
	.then(result => {
		if (doPatch) {
			dispatch('patch_user');
		}
		return result;
	});
};

export const submit_swap_auto = ({commit, getters}, {userId, orderId}) => {
	return Api.post({
		commit: commit,
		endpoint: `users/${userId}/orders/${orderId}/auto-swap`,
		token: getters.token,
		body: {}
	});
};

export const meal_like_toggle = ({commit, getters}, meal_id) => {
	return Api.post({
		commit: commit,
		endpoint: `users/${getters.get_user_id}/meals/likes/${meal_id}`,
		token: getters.token,
		body: {}
	})
	.then(result => {
		commit('set_likes', result.likes);
	})
	.catch( () => {
		// todo: handle error
	});
};

export const get_meal_likes = ({commit, getters}) => {
	return Api.post({
		commit: commit,
		endpoint: `users/${getters.get_user_id}/meals/likes`,
		token: getters.token,
		body: {}
	})
	.then(result => {
		commit('set_likes', result.likes);
	});
};

export const delete_orphan_meals = ({commit, getters}) => {
	return Api.get({
		commit: commit,
		endpoint: `meals/clean`,
		token: getters.token
	});
};

export const get_delivery_offsets = ({commit, getters}) => {
	return Api.get({
		commit: commit,
		endpoint: `meals/offsets`,
		token: getters.token
	})
	.then(result => {
		commit('set_delivery_offsets', result);
	});
};


export const save_delivery_offsets = ({commit, getters}, {payloadOffsets}) => {
	return Api.post({
		commit: commit,
		endpoint: `meals/offsets`,
		token: getters.token,
		body: payloadOffsets
	})
	.then(result => {
		if (result.error) {
			// TODO: Handle Error
		} else {
			//commit('save_meal_plan', result.mealPlan)
		}
		return result;
	})
	.catch( () => {
		// todo: handle error
	});
};