export const is_loading = state => {
	return state.ui.loading;
};

export const is_locked = state => {
	if (sessionStorage.getItem('ui_locked')) {
		state.ui.locked = sessionStorage.getItem('ui_locked') === 'true';
	} else {
		state.ui.locked = process.env.NODE_ENV === 'staging';
	}
	return state.ui.locked;
};

export const get_loading_semaphore = state => {
	return state.ui.loader_semaphore;
};

export const is_fetching = state => {
	return state.ui.fetching_semaphore > 0;
};

export const get_site_status = state => {
	return state.ui.site_status;
};

// export const get_nutrition_toggle = state => {
// 	return state.ui.nutrition_facts_toggle;
// };