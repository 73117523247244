<template>
	<div class="modal" tabindex="-1" role="dialog">
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<div class="modal-body">
					<p class="modal-title" id="loadingModalTitle">{{modal_title ? modal_title : 'Loading'}}</p>
					<p>{{modal_message ? modal_message : 'one moment...'}}</p>
					<div class="loader">
						<!--<loading-spinner/>-->
						<vue-loading spinner="circles"/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import VueLoading from 'vue-simple-loading';

	export default {
		components: {
			VueLoading,
		},
		data() {
			return {};
		},
		mounted() {
		},
		computed: {
			modal_title() {
				return this.$store.state.ui.loading_modal_title;
			},
			modal_message() {
				return this.$store.state.ui.loading_modal_message;
			}
		}
	};
</script>

<style lang="scss" scoped>
	@import '../../styles/scss/colors';

	.modal {
		display: block;
		background-color: rgba(0, 0, 0, 0.7);
		color: #FFF;
	}

	.modal-content {
		background-color: transparent;
		border: none;
	}

	h3 {
		color: white;
		width: 100%;
		text-align: center;
	}

	.modal-body {
		text-align: center;
		padding: 50px;
		p {
			color: #FAFAFA;
			font-weight: bold;
			font-size: 22px;
			margin-bottom: 25px;
		}
	}

	.loader {
		display: flex;
		justify-content: space-around;
	}
</style>
