<template>
    <div>
        <form v-on:submit.prevent="validateBeforeSubmit">
            <div class="row justify-content-center px-lg-5">
                <div class="col-10">

                    <div class="row justify-content-center">
                        <div class="col-12 text-center">
                            <h1>Reset My Password</h1>
                        </div>
                    </div>

                    <div class="row justify-content-center mt-3">
                        <p>Thank you for confirming your email.</p>
                        <p>Please complete your password change by entering a new password.</p>
                    </div>


                    <div class="row mt-3">
                        <div class="col-12 input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">Password</span>
                            </div>
                            <input class="form-control"
                                     type="password"
                                     v-model="password"
                                     name="Password"
                                     id="password"
                                     v-validate="{'required': true, 'min': 8}"
                                     :class="{'input': true, 'is-danger': errors.has('Password')}"/>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-12">
                            <button class="btn btn-primary w-100" :disabled="loading">
                                {{loading ? 'Updating Password' : 'Change My Password'}}
                                <icon fill="currentColor" name="spinner" spin class="loading-icon ml-1" v-if="loading"/>
                            </button>
                        </div>
                    </div>
                    <div class="row mt-5 justify-content-between px-3">
                        <div class="col-4 text-left">
                            <router-link class="" to="log-in">Log In</router-link>
                        </div>
                        <div class="col-4 text-right">
                            <router-link class="" to="sign-up">Sign Up</router-link>
                        </div>
                    </div>

                </div>
            </div>
        </form>
    </div>
</template>

<script>
    export default {
        name: 'auth-reset-password',
        data() {
            return {
                loading: false,
                password: null
            };
        },
        methods: {
            validateBeforeSubmit() {
                this.$validator.validateAll()
                .then(result => {
                    if (result) {
                        this.resetPassword();
                    }
                });
            },
            resetPassword() {
                let token = window.location.href.substr(window.location.href.indexOf('token=') + 6);

                let payload = {
                    token: token,
                    password: this.password
                };

                this.loading = true;
                this.$store.dispatch('reset_password', payload)
                .then(result => {
                    this.loading = false;
                    if (result.error) {
                        this.$snotify.error(result.error);
                    } else {
                        if (result.user) {
                            this.$router.push('/dashboard');
                        }
                    }
                });
            }
        }
    };
</script>

<style scoped>
    .input-group-text {
        width: 100px;
    }
</style>
