export const get_meals = (state) => {
	return state.meals.meals || [];
};

export const get_meal_plans = (state) => {
	return state.meals.meal_plans || [];
};

export const get_meal_plan_infos = (state) => {
	return state.meals.meal_plan_infos || [];
};

export const get_meal_plans_meals = (state) => {
	return state.meals.meal_plans_meals || [];
};

export const get_meal_time_string = () => (id) => {
	return id === 0 ? 'Breakfast' : id === 1 ? 'Lunch' : 'Dinner';
};

export const get_meal_likes = (state) => {
	return state.meals.likes || null;
};

export const get_delivery_offsets = (state) => {
	return state.meals.delivery_offsets || {};
};
