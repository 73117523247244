import Vue from 'vue';
import App from './App';
import router from './router';
import {store} from './vuex/store';
import * as VeeValidate from 'vee-validate';

// import 'vue-awesome/icons'
import 'vue-awesome/icons/landmark'
// import 'vue-awesome/icons/map-marked-alt'
// import 'vue-awesome/icons/map-marked'
import 'vue-awesome/icons/map-marker-alt'
import 'vue-awesome/icons/map-marker'
import 'vue-awesome/icons/map-pin'
// import 'vue-awesome/icons/map-signs'
// import 'vue-awesome/icons/map'
import 'vue-awesome/icons/address-card'
import 'vue-awesome/icons/align-justify'
import 'vue-awesome/icons/angle-double-left'
import 'vue-awesome/icons/angle-left'
import 'vue-awesome/icons/arrow-left'
import 'vue-awesome/icons/arrow-right'
import 'vue-awesome/icons/bell'
import 'vue-awesome/icons/brands/cc-amex'
import 'vue-awesome/icons/brands/cc-discover'
import 'vue-awesome/icons/brands/cc-mastercard'
import 'vue-awesome/icons/brands/cc-visa'
import 'vue-awesome/icons/building'
import 'vue-awesome/icons/calendar'
import 'vue-awesome/icons/chart-line'
import 'vue-awesome/icons/check'
import 'vue-awesome/icons/clock'
import 'vue-awesome/icons/cloud-upload-alt'
import 'vue-awesome/icons/comment-alt'
import 'vue-awesome/icons/credit-card'
import 'vue-awesome/icons/dollar-sign'
import 'vue-awesome/icons/download'
import 'vue-awesome/icons/edit'
import 'vue-awesome/icons/envelope'
import 'vue-awesome/icons/exchange-alt'
import 'vue-awesome/icons/exclamation-circle'
import 'vue-awesome/icons/eye'
import 'vue-awesome/icons/file-alt'
import 'vue-awesome/icons/heart'
import 'vue-awesome/icons/heartbeat'
import 'vue-awesome/icons/history'
import 'vue-awesome/icons/home'
import 'vue-awesome/icons/info-circle'
import 'vue-awesome/icons/list'
import 'vue-awesome/icons/list-ol'
import 'vue-awesome/icons/lock'
import 'vue-awesome/icons/male'
import 'vue-awesome/icons/map-marker'
import 'vue-awesome/icons/minus'
import 'vue-awesome/icons/money-bill-alt'
import 'vue-awesome/icons/pencil-alt'
import 'vue-awesome/icons/plus'
import 'vue-awesome/icons/plus'
import 'vue-awesome/icons/print'
import 'vue-awesome/icons/question'
import 'vue-awesome/icons/question-circle'
import 'vue-awesome/icons/redo'
import 'vue-awesome/icons/retweet'
import 'vue-awesome/icons/save'
import 'vue-awesome/icons/shipping-fast'
import 'vue-awesome/icons/shopping-cart'
import 'vue-awesome/icons/sign-out-alt'
import 'vue-awesome/icons/spinner'
import 'vue-awesome/icons/tachometer-alt'
import 'vue-awesome/icons/tag'
import 'vue-awesome/icons/tags'
import 'vue-awesome/icons/thumbs-down'
import 'vue-awesome/icons/thumbs-up'
import 'vue-awesome/icons/times'
import 'vue-awesome/icons/times-circle'
import 'vue-awesome/icons/trash'
import 'vue-awesome/icons/truck'
import 'vue-awesome/icons/university'
import 'vue-awesome/icons/user'
import 'vue-awesome/icons/user'
import 'vue-awesome/icons/user-circle'
import 'vue-awesome/icons/user-plus'
import 'vue-awesome/icons/user-plus'
import 'vue-awesome/icons/user-secret'
import 'vue-awesome/icons/users'



import Icon from 'vue-awesome/components/Icon';
import Snotify, {SnotifyPosition} from 'vue-snotify';
import VueEventCalendar from 'vue-event-calendar';
import VueAnalytics from 'vue-analytics';
import * as VTooltip from 'v-tooltip';
import $ from 'jquery'


Vue.config.productionTip = true;
Vue.component('icon', Icon);

const vvConfig = {
    errorBagName: 'errors',
    fieldsBagName: 'vvFieldsBag',
};

Vue.use(VeeValidate, vvConfig);

Vue.use(VTooltip);

Vue.use(Snotify, {
	toast: {
		position: SnotifyPosition.rightTop,
		timeout: 3000,
		newOnTop: false
	}
});
Vue.use(VueEventCalendar, {locale: 'en'});

Vue.use(VueAnalytics, {
	id: process.env.VUE_APP_GOOGLE_UA_ID,
	router,
	ecommerce: {
		enabled: true,
		enhanced: true
	},
	debug: {
		enabled: false//process.env.NODE_ENV === 'development'
	}
});

Vue.prototype.$eventHub = new Vue();

router.beforeEach((to, from, next) => {
	$(function () {
		if (to.path.endsWith('printable')) {
			$('#app').addClass('no-footer');
			$('#content-wrapper').addClass('no-footer');
		} else {
			$('#app').removeClass('no-footer');
			$('#content-wrapper').removeClass('no-footer');
		}
	});
	next();
});

new Vue({
	el: '#app',
	router: router,
	store: store,
	template: '<App/>',
	components: {App}
});

