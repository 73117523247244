<template>
	<footer class="footer ">
		<div class="container-fluid">
			<div class="row">
				<div class="left col-12 col-md-9">
					<a class="footer-link d-block d-md-inline d-print-none" href="https://healthyforlifemeals.com/terms-of-service">Terms of Service</a>
					<a class="footer-link d-block d-md-inline d-print-none" href="https://healthyforlifemeals.com/privacy-policy">Privacy</a>
					<a class="footer-link d-block d-md-inline" href="https://www.healthyforlifemeals.com/">© 2024 Healthy For Life Meals</a>
				</div>
				<div class="help col-md-block col-md-3 d-none d-md-block d-print-none">
					<icon fill="currentColor" name="spinner" spin class="mr-3" v-show="api_call_in_progress"/>
					<a href="https://support.healthyforlifemeals.com">Need Help?</a>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
	export default {
		name: 'FooterNav',
		computed: {
			user() {
				return this.$store.getters.user;
			},
			api_call_in_progress() {
				return this.$store.getters.is_agent && this.$store.getters.is_fetching;
			}
		},
		mounted() {
		},
		methods: {}
	};
</script>

<style scoped lang="scss">
	@import '../../styles/scss/fonts';
	@import '../../styles/scss/colors';

	.footer {
		border-top: 1px solid $silver;
		width: 100%;
		min-height: 60px;
		line-height: 60px;
		background: white;
		box-shadow: 0 -5px 5px -5px #EAEAEA;
		color: $dark-grey;
		.left {
			text-align: left;
			a {
				color: #596A81;
			}
		}
		.help {
			text-align: right;
			right: 35px;
			li {
				a {
					color: #79589F;
				}
			}
		}
	}

	.footer-link {
		margin-left: 25px;
		margin-right: 25px;
	}

	@media(max-width: 600px) {
		.footer {
			padding-top: 15px;
			padding-bottom: 15px;
			line-height: 20px;
			.left {
				text-align: center;
			}
		}
	}

	.help {
		a {
			color: $purple;
		}
	}
</style>
