import * as Api from '../api.js';

export const admin_user_search = ({commit, getters}, {query, column, limit}) => {
	return Api.get({
		commit: commit,
		endpoint: `admin/users/search?column=${column}&query=${query}&limit=${limit}`,
		token: getters.token
	})
	.then(result => {
		if (result.error) {
			// TODO: Handle Error
		} else {
			if (result.users.length === limit) {
				// Page 1. Missed some users.
			}
			commit('set_user_search_results', result.users);
		}
		return result;
	});
};

export const clear_admin_user = ({commit}) => {
	commit('set_admin_current_user', null);
};

export const get_admin_user = ({commit, getters}, {userId}) => {
	return Api.get({
		commit: commit,
		endpoint: `users/${userId}`,
		token: getters.token
	})
	.then(result => {
		if (result.error) {
			// TODO: Handle Error
		} else {
			commit('set_admin_current_user', result.user);
		}
		return result;
	});
};

export const get_admin_distributor_orders = ({commit, getters}, {distributor_id, order_day, flex}) => {
	return Api.get({
		commit: commit,
		endpoint: `admin/reports/distributors/${distributor_id}?date=${order_day}&flex=${flex}`,
		token: getters.token
	})
	.then(result => {
		if (result.error) {
			// no error handling
		}
		return result;
	})
};

export const save_distributor = ({commit, getters}, {distributor}) => {
	return Api.post({
		commit: commit,
		endpoint: `distributors/${distributor.id}`,
		token: getters.token,
		body: distributor
	})
	.then(result => {
		if (result.error) {

			// TODO: Handle Error
		} else {
			commit('set_distributor', result.distributor);
		}
		return result;
	})
	.catch(error => {

		throw error;
	});
};

export const get_admin_distributors = ({commit, getters}) => {
	return Api.get({
		commit: commit,
		endpoint: `admin/distributors`,
		token: getters.token
	})
	.then(result => {
		if (result.error) {
			//  todo: handle error
		} else {
			commit('set_admin_distributor_ids', result.distributorIds);
		}
		return result;
	});
};

export const get_admin_report_totals = ({commit, getters}, {order_day, invoice, flex}) => {
	return Api.get({
		commit: commit,
		endpoint: `admin/reports/totals?date=${order_day}&invoice=${invoice}&flex=${flex}`,
		token: getters.token
	});
};

export const get_admin_report_subscriptions = ({commit, getters}) => {
	return Api.get({
		commit: commit,
		endpoint: `admin/reports/subscriptions`,
		token: getters.token
	});
};

export const get_admin_report_kitchen = ({commit, getters}, {order_day, distributorId, flex}) => {
	return Api.get({
		commit: commit,
		endpoint: `admin/reports/kitchen?date=${order_day}&distributorId=${distributorId}&flex=${flex}`,
		token: getters.token
	});
};

export const get_admin_report_daily = ({commit, getters}, {date, distributorId, reportType}) => {
	return Api.get({
		commit: commit,
		endpoint: `admin/reports/daily?date=${date}&distributorId=${distributorId}&reportType=${reportType}`,
		token: getters.token
	});
};

export const get_admin_report_charges = ({commit, getters}, {distributorId}) => {
	return Api.get({
		commit: commit,
		endpoint: `admin/reports/charges?distributorId=${distributorId}`,
		token: getters.token
	});
};

export const get_admin_coupons = ({commit, getters}) => {
	return Api.get({
		commit: commit,
		endpoint: `coupons/coupons`,
		token: getters.token
	})
	.then(result => {
		if (result.error) {
			// TODO: Handle Error
		} else {
			commit('set_admin_coupons', result.coupons);
		}
		return result;
	})
};

export const save_admin_coupon = ({commit, getters}, coupon) => {
	return Api.post({
		commit: commit,
		endpoint: `coupons/coupons/${coupon.id}`,
		token: getters.token,
		body: coupon
	})
	.then(result => {
		if (result.error) {
			// TODO: Handle Error
		} else {
			commit('set_admin_coupon', result.coupon);
		}
		return result;
	})
};

export const delete_admin_coupon = ({commit, getters}, coupon) => {
	return Api.destroy({
		commit: commit,
		endpoint: `coupons/coupons/${coupon.id}`,
		token: getters.token
	})
	.then(result => {
		if (result.error) {
			// TODO: Handle Error
		} else {
			commit('delete_admin_coupon', coupon);
		}
		return result;
	})
};

export const get_order_item_subs_admin = ({commit, getters}, payload) => {
	const {user_id, order_id, item_id} = payload;
	return Api.get({
		commit: commit,
		endpoint: `users/${user_id}/orders/${order_id}/items/${item_id}/subs`,
		token: getters.token
	});
};

export const update_order = ({commit, getters}, {orderId, update}) => {
	return Api.update({
		commit: commit,
		endpoint: `admin/orders/${orderId}`,
		token: getters.token,
		body: {update: update}
	});
};

export const update_order_item = ({commit, getters}, {orderId, orderItemId, update}) => {
	return Api.update({
		commit: commit,
		endpoint: `admin/orders/${orderId}/${orderItemId}`,
		token: getters.token,
		body: {update: update}
	});
};
export const save_meal_image = ({commit, getters}, {meal_id, body}) => {
	return Api.uploadRawApi({
		commit: commit,
		endpoint: `admin/meals/${meal_id}/image`,
		token: getters.token,
		body: body
	})
	.then(result => {
		if (result.error) {
			// TODO: Handle Error
		}
		return result;
	})
};

export const save_meal_plan_meals_csv = ({commit, getters}, {body}) => {
	return Api.uploadRawApi({
		commit: commit,
		endpoint: `admin/meals/csv`,
		token: getters.token,
		body: body
	})
	.then(result => {
		if (result.error) {
			// TODO: Handle Error
		}
		return result;
	})
};

export const add_distributor_admin = ({commit, getters}, {id, email, admin, finance}) => {
	return Api.post({
		commit: commit,
		endpoint: `admin/distributors/${id}`,
		token: getters.token,
		body: {
			email: email,
			admin: admin,
			finance: finance
		}
	});
};

export const add_driver = ({commit, getters}, {email}) => {
	return Api.post({
		commit: commit,
		endpoint: `admin/drivers`,
		token: getters.token,
		body: {email: email}
	});
};

export const get_distributor_admins = ({commit, getters}, {id}) => {
	return Api.get({
		commit: commit,
		endpoint: `admin/distributors/${id}`,
		token: getters.token
	});
};
export const get_drivers = ({commit, getters}) => {
	return Api.get({
		commit: commit,
		endpoint: `admin/drivers`,
		token: getters.token
	});
};

export const remove_distributor_admin = ({commit, getters}, {id, userId, finance}) => {
	return Api.destroy({
		commit: commit,
		endpoint: `admin/distributors/${id}/${userId}`,
		token: getters.token,
		body: {finance: finance}
	});
};
export const remove_driver = ({commit, getters}, {userId}) => {
	return Api.destroy({
		commit: commit,
		endpoint: `admin/drivers/${userId}`,
		token: getters.token
	});
};

export const get_order_transaction = ({commit, getters}, {orderId}) => {
	return Api.get({
		commit: commit,
		endpoint: `admin/orders/${orderId}/transaction`,
		token: getters.token
	});
};

export const get_distributor_subscriptions = ({commit, getters}, {distributorId}) => {
	return Api.get({
		commit: commit,
		endpoint: `admin/distributors/${distributorId}/subscriptions`,
		token: getters.token
	});
};

export const get_subscriptions_charge_preview = ({commit, getters}, {date}) => {
	return Api.get({
		commit: commit,
		endpoint: `payments/jobs/subscriptions/charge/preview?date=${date}`,
		token: getters.token
	});
};

export const toggle_site_down_or_up = ({commit, getters}, payload) => {
	return Api.post({
		commit: commit,
		endpoint: `admin/maintenance-status`,
		token: getters.token,
		body: payload
	});
};

export const get_site_status = ({commit, getters}) => {
	return Api.get({
		commit: commit,
		endpoint: `admin/maintenance-status`,
		token: getters.token,
	});
};

// export const toggle_nutrition_on_or_off = ({commit, getters}, payload) => {
// 	return Api.post({
// 		commit: commit,
// 		endpoint: `admin/nutrition-toggle`,
// 		token: getters.token,
// 		body: payload
// 	});
// };

// export const get_nutrition_toggle = ({commit, getters}) => {
// 	return Api.get({
// 		commit: commit,
// 		endpoint: `admin/nutrition-toggle`,
// 		token: getters.token,
// 	});
// };
export const get_users_by_month = ({commit, getters}) => {
	return Api.get({
		commit: commit,
		endpoint: `admin/users-by-month`,
		token: getters.token,
	});
};

export const get_report_orders_by_week_and_day = ({commit, getters}, week) => {
	return Api.post({
		commit: commit,
		endpoint: `admin/orders-by-week`,
		token: getters.token,
		body: week
	});
};

export const get_order_totals = ({commit, getters}) => {
	return Api.get({
		commit: commit,
		endpoint: `metrics/sales`,
		token: getters.token,
	});
};

export const get_report_order_totals_range = ({commit, getters}, {start, end}) => {
	return Api.post({
		commit: commit,
		endpoint: `metrics/sales-totals/${start}/${end}`,
		token: getters.token
	}).then(totals => {
		console.log(totals);
		commit('set_totals', totals);
	});
};

export const get_report_order_totals_range_user_data = ({commit, getters}, {start, end}) => {
	return Api.post({
		commit: commit,
		endpoint: `metrics/sales-totals/${start}/${end}/users`,
		token: getters.token
	}).then(totals => {
		console.log(totals);
		commit('set_totals_user_data', totals);
	});
};

export const get_order_items_csv = ({commit, getters}, {date}) => {
	return Api.post({
		commit: commit,
		endpoint: `metrics/orders/csv`,
		token: getters.token,
		body: {date: date}
	});
};

export const add_coupon_to_user_order = ({commit, getters}, {orderId, coupon}) => {
	return Api.update({
		commit: commit,
		endpoint: `payments/coupons/order/${orderId}/apply-coupon`,
		token: getters.token,
		body: coupon
	});
};

export const get_active_users = ({commit, getters}) => {
	return Api.get({
		commit: commit,
		endpoint: `metrics/active-users`,
		token: getters.token,
	});
};

export const get_initiatives = ({commit, getters}) => {
	return Api.get({
		commit: commit,
		endpoint: `metrics/initiatives`,
		token: getters.token,
	});
};

export const get_initiative = ({commit, getters}, id) => {
	return Api.get({
		commit: commit,
		endpoint: `metrics/initiative/${id}`,
		token: getters.token,
	});
};

export const delete_initiative = ({commit, getters}, id) => {
	return Api.destroy({
		commit: commit,
		endpoint: `metrics/initiative/${id}`,
		token: getters.token,
	});
};

export const update_initiative = ({commit, getters}, {initiative, id}) => {
	return Api.patch({
		commit: commit,
		endpoint: `metrics/initiative/${id}`,
		token: getters.token,
		body: initiative
	});
};

export const create_initiative = ({commit, getters}, initiative) => {
	return Api.post({
		commit: commit,
		endpoint: `metrics/initiative`,
		token: getters.token,
		body: initiative
	});
};

export const get_endpoints = ({commit, getters}) => {
	return Api.get({
		commit: commit,
		endpoint: `metrics/endpoints`,
		token: getters.token,
	});
};

export const update_cutoff = ({commit, getters}, update) => {
	return Api.post({
		commit: commit,
		endpoint: `admin/update-cutoff`,
		token: getters.token,
		body: update
	});
};

export const user_query_report = ({commit, getters}, query) => {
	return Api.get({
		commit: commit,
		endpoint: `metrics/user-query${query}`,
		token: getters.token,
	});
};

export const get_users_coupon_redemptions = ({commit, getters}, couponId) => {
	return Api.get({
		commit: commit,
		endpoint: `metrics/coupon-redemptions/${couponId}`,
		token: getters.token,
	});
};

export const generate_promo_codes = ({commit, getters}, generate) => {
	return Api.post({
		commit: commit,
		endpoint: `coupons/promoCodes/${generate.couponId}/generate`,
		token: getters.token,
		body: generate
	});
};

export const update_promo_codes = ({commit, getters}, couponId) => {
	return Api.post({
		commit: commit,
		endpoint: `coupons/promoCodes/${couponId}`,
		token: getters.token,
		body: {}
	});
};

export const get_promo_codes = ({commit, getters}, couponId) => {
	return Api.get({
		commit: commit,
		endpoint: `coupons/promoCodes/${couponId}`,
		token: getters.token
	});
};


