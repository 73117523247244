const AdminUserInfo  = () => import('@/components/Admin/AdminUserInfo')
const AdminUserCreditCards  = () => import('@/components/Admin/AdminUserCreditCards')
const AdminUserOrders  = () => import('@/components/Admin/AdminUserOrders')
const AdminUserAddresses  = () => import('@/components/Account/AccountAddress')
const AdminUserPlaceOrder  = () => import('@/components/Admin/AdminUserPlaceOrder')
const AdminUserSubscriptions  = () => import('@/components/Admin/AdminUserSubscriptions')
const AdminUserOrderEdit  = () => import('@/components/Admin/AdminUserOrderEdit')
const AdminUserSubscriptionEdit  = () => import('@/components/Admin/AdminUserSubscriptionEdit')

export const admin_user_children = [
	{
		path: 'profile',
		name: 'AdminUserInfo',
		component: AdminUserInfo
	},
	{
		path: 'billing',
		name: 'AdminUserCreditCards',
		component: AdminUserCreditCards
	},
	{
		path: 'orders',
		name: 'AdminUserOrders',
		component: AdminUserOrders
	},
	{
		path: 'orders/:orderId',
		name: 'AdminUserOrderEdit',
		component: AdminUserOrderEdit
	},
	{
		path: 'addresses',
		name: 'AdminUserAddresses',
		component: AdminUserAddresses
	},
	{
		path: 'subscriptions',
		name: 'AdminUserSubscriptions',
		component: AdminUserSubscriptions
	},
	{
		path: 'subscriptions/:subscriptionId',
		name: 'AdminUserSubscriptionEdit',
		component: AdminUserSubscriptionEdit
	},
	{
		path: 'place-order',
		name: 'AdminUserPlaceOrder',
		component: AdminUserPlaceOrder
	},
];
