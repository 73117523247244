<template>

    <div class="row justify-content-center px-lg-5">
        <div class="col-10">

            <div class="row justify-content-center">
                <div class="col-12 text-center">
                    <h1>Confirm Your Email</h1>
                </div>
            </div>

            <div class="row justify-content-center mt-3">
                <div class="col-12 text-center" v-if="!showNote">
                    <p>Please enter your email to confirm it in our system.</p>
                </div>
                <div class="col-12 text-center font-weight-bold" v-else>
                    <p class="font-weight-bold text-primary">Thank you for confirming your email!</p>
                    <p class="font-weight-bold text-primary" v-if="showResetNote">You will need to reset your password before you
                        can log in. You should receive an e-mail shortly with a link to reset your password.</p>
                    <p v-else>Please
                        <router-link class="" to="log-in">log in</router-link>
                        to continue.
                    </p>
                </div>
            </div>

            <form v-if="showForm" v-on:submit.prevent="validateBeforeSubmit">
                <div class="row mt-3">
                    <div class="col-12 input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><icon fill="currentColor" name="envelope" class="icon"/></span>
                        </div>
                        <input class="form-control"
                                 type="text"
                                 v-model="email"
                                 name="email"
                                 v-validate="{'required': true, 'email': true}"
                                 :class="{'input': true, 'is-danger': errors.has('E-Mail')}"
                                 :disabled="loading"/>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-12">
                        <button class="btn btn-primary w-100">
                            {{loading ? 'Confirming Email' : 'Confirm Email'}}
                            <icon fill="currentColor" name="spinner" spin class="loading-icon ml-1" v-if="loading"/>
                        </button>
                    </div>
                </div>
            </form>

            <div class="row mt-5 justify-content-between px-3">
                <div class="col-4">
                    <router-link style="font-weight: bold;" to="log-in">Log In</router-link>
                </div>
                <div class="col-4 text-right">
                    <router-link class="" to="sign-up">Sign Up</router-link>
                </div>
            </div>

        </div>
    </div>


</template>

<script>
    export default {
        name: 'auth-confirm-email',
        components: {},
        data() {
            return {
                loading: false,
                showNote: false,
                showForm: true,
                showResetNote: false,
                email: null
            };
        },
        methods: {
            validateBeforeSubmit() {
                this.$validator.validateAll()
                .then(result => {
                    if (result) {
                        this.confirmEmail();
                    }
                });
            },
            confirmEmail() {
                this.loading = true;
                let confirmCode = window.location.href.substr(window.location.href.indexOf('code=') + 5);
                this.$store.dispatch('confirm_email', {
                    email: this.email,
                    confirmCode: confirmCode
                })
                .then(result => {
                    this.loading = false;

                    if (result.confirmed) {
                        this.showNote = true;
                        this.showForm = false;
                        if (result.resetPassword) {
                            this.showResetNote = true;
                        }
                    } else if (result.error) {
                        this.$snotify.error(result.error);
                    }
                });
            }
        }
    };
</script>
