export const admin_state = {
	admin: {
		distributor_ids: [],
		userSearchResults: [],
		currentUser: null,
		coupons: [],
		orderTotals: null,
		orderTotalsUserData: null,
		range: {
			start: null,
			end: null
		},
		adminPaths: [
			{
				path: '/admin/customers',
				style: 'customers',
				name: 'Customers',
				check: {_if: 'is_agent'}
			},
			{
				path: '/admin/orders-report',
				style: 'orders-report',
				name: 'Orders Report',
				check: {_if: 'is_agent'}
			},
			{
				path: '/admin/order-items-by-week',
				style: 'order-items-by-week',
				name: 'Order Items CSV',
				check: {_if: 'is_agent'}
			},
			{
				path: '/admin/progress-report',
				style: 'progress-report',
				name: 'Progress Report',
				check: {_if: 'is_agent OR is_driver'}
			},
			{
				path: '/admin/delivery-report',
				style: 'delivery-report',
				name: 'Delivery Report',
				check: {_if: 'is_agent OR is_driver'}
			},
			{
				path: '/admin/charges-report',
				style: 'charges-report',
				name: 'Charges Report',
				check: {_if: 'is_agent'}
			},
			{
				path: '/admin/user-query-report',
				style: 'user-query-report',
				name: 'User Query Report',
				check: {_if: 'is_admin'}
			},
			{
				path: '/admin/daily-report',
				style: 'daily-report',
				name: 'Daily Report',
				check: {_if: 'is_agent'}
			},
			{
				path: '/admin/kitchen-report',
				style: 'kitchen-report',
				name: 'Kitchen Report',
				check: {_if: 'is_super_admin OR is_driver'}
			},
			{
				path: '/admin/route-report',
				style: 'route-report',
				name: 'Route Report',
				check: {_if: 'is_super_admin OR is_driver'}
			},
			{
				path: '/admin/subscriptions-report',
				style: 'subscriptions-report',
				name: 'Subscriptions Report',
				check: {_if: 'is_super_admin'}
			},
			{
				path: '/admin/distributors',
				style: 'distributors',
				name: 'Distributors',
				check: {_if: 'is_agent'}
			},
			{
				path: '/admin/subscriptions',
				style: 'subscriptions',
				name: 'Subscriptions',
				check: {_if: 'is_agent'}
			},
			{
				path: '/admin/manage-meals',
				style: 'manage-meals',
				name: 'Manage Meals',
				check: {_if: 'is_super_admin'}
			},
			{
				path: '/admin/manage-meal-plans',
				style: 'manage-meal-plans',
				name: 'Manage Meal Plans',
				check: {_if: 'is_super_admin'}
			},
			{
				path: '/admin/manage-delivery-offsets',
				style: 'manage-delivery-offsets',
				name: 'Manage Delivery Offsets',
				check: {_if: 'is_super_admin'}
			},
			{
				path: '/admin/state-tax',
				style: 'state-tax',
				name: 'State Tax',
				check: {_if: 'is_super_admin'}
			},
			{
				path: '/admin/coupons',
				style: 'coupons',
				name: 'Coupons',
				check: {_if: 'is_super_admin'}
			},
			{
				path: '/admin/upload-meal-csv',
				style: 'upload-meal-csv',
				name: 'Upload Meal CSV',
				check: {_if: 'is_super_admin'}
			},
			{
				path: '/admin/stripe-account',
				style: 'stripe-account',
				name: 'Stripe Account',
				check: {_if: 'is_finance'}
			},
			{
				path: '/admin/settings',
				style: 'settings',
				name: 'Settings',
				check: {_if: 'is_super_admin'}
			},
			{
				path: '/admin/charge-preview',
				style: 'charge-preview',
				name: 'Charge Preview',
				check: {_if: 'is_super_admin'}
			},
			{
				path: '/admin/drivers',
				style: 'drivers',
				name: 'Drivers',
				check: {_if: 'is_super_admin'}
			},
			{
				path: '/admin/notifications',
				style: 'notifications',
				name: 'Notifications',
				check: {_if: 'is_super_admin'}
			},
		]
	}
};
