export const ui_state = {
	ui: {
		sub_menu: false,
		loading: false,
		loader_semaphore: 0,
		loading_modal_title: '',
		loading_modal_message: '',
		loading_message: '',
		stripe_loading: false,
		current_view: 'Account',
		account_view: '',
		admin_view: '',
		show_nav: true,
		locked: true,
		fetching_semaphore: 0,
		site_status: 'up',
		nutrition_facts_toggle: 1
	}
};
