<template>
    <nav class="navbar navbar-expand-lg navbar-light" v-if="user">

        <img class="hflmLogo d-none d-lg-block" src="/static/HFLM_Logo_Horizontal.png" v-on:click="marketing_home"/>
        <img class="hflmLogo-mobile icon d-block d-lg-none" src="/static/HFLM_Logo_Horizontal.png" v-on:click="marketing_home"/>

        <input type="checkbox" id="toggle-menu" class="toggle-menu" name="" data-toggle="collapse"
                 data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false"
                 aria-label="Toggle navigation"/>

        <label for="toggle-menu" class=" d-block d-lg-none">
            <span class="menu-bar"></span>
            <span class="menu-bar"></span>
            <span class="menu-bar"></span>
        </label>


        <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
            <ul class="d-sm-block d-md-block d-lg-none navbar-nav">

                <li class="nav-item not-sub">
                    <router-link to="/dashboard" class="nav-link active">Dashboard</router-link>
                </li>

                <li class="nav-item not-sub">
                    <router-link to="/orders/choose-your-plan" class="nav-link">Order Now</router-link>
                </li>

                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" id="navbarDropdownlink" role="button"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" href="#">
                        Account
                    </a>
                    <ul class="dropdown-menu sub col-12" role="menu" aria-labelledby="navbarDropdownlink">

                        <li class="nav-link">
                            <router-link to="/account" class="dropdown-item">
                                <icon fill="currentColor" name="user" class="icon-nav"></icon>
                                Profile
                            </router-link>
                        </li>

                        <li class="nav-link">
                            <router-link to="/account/subscriptions" class="dropdown-item">
                                <icon fill="currentColor" name="calendar" class="icon-nav"></icon>
                                Subscriptions
                            </router-link>
                        </li>

                        <li class="nav-link">
                            <router-link to="/account/address" class="dropdown-item">
                                <icon fill="currentColor" name="address-card" class="icon-nav"></icon>
                                Address
                            </router-link>
                        </li>

                        <li class="nav-link">
                            <router-link to="/account/billing" class="dropdown-item">
                                <icon fill="currentColor" name="credit-card" class="icon-nav"></icon>
                                Billing
                            </router-link>
                        </li>

                        <li class="nav-link">
                            <router-link to="/account/past-orders" class="dropdown-item">
                                <icon fill="currentColor" name="history" class="icon-nav"></icon>
                                Past Orders
                            </router-link>
                        </li>

                    </ul>
                </li>
                <!--ADMIN SUBMENU-->
                <li class="nav-item dropdown" v-if="is_agent">
                    <a class="nav-link dropdown-toggle" id="navbarDropdownlinkAdmin" role="button"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" href="#">
                        Admin
                    </a>
                    <ul class="dropdown-menu sub col-12" role="menu" aria-labelledby="navbarDropdownlink">
                        <li>
                             <!--re-using sidebar component here to simplify admin_paths maintenance-->
                            <sidebar class="col-3 nav-link dropdown-item" :paths="admin_paths" tag="li"/>
                        </li>
                    </ul>
                </li>
                <!--END ADMIN SUBMENU-->


                <li class="nav-item"><a class="nav-link" href="#" v-on:click.prevent="log_out">Log Out</a></li>

            </ul>
        </div>


        <div class="row d-none d-lg-flex">
            <!--<router-link class="btn button-9" to="/orders/choose-your-plan" v-if="user">PlaceOrder Today!</router-link>-->
            <router-link class="btn button-9 mb-4 mr-5" to="/authenticate/welcome" v-if="!user">Log In</router-link>


            <li class="nav-item dropdown user-dropdown" v-if="user">

                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle=""
                    aria-haspopup="true" aria-expanded="false">
                    <img v-if="avatar" class="avatar" :src="avatar_url"/>
                    <icon v-if="avatar === false" name="user-circle" class="user-circle"></icon>
                    <p class="silver"><span class="capitalize">Hi, {{user.firstName}}</span></p>
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a class="dropdown-item" href="https://support.healthyforlifemeals.com">Support</a>
                    <a class="dropdown-item" href="#">Log Out</a>
                </div>

            </li>

            <div class="sub-menu mr-5 mb-4">

                <input type="checkbox" id="toggle-menu-2" class="toggle-menu-2" name="" data-toggle="collapse"
                         data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false"
                         aria-label="Toggle navigation"
                         v-on:click="toggle_sub_menu"
                />

                <label for="toggle-menu-2" class="d-block">
                    <span class="menu-bar"></span>
                    <span class="menu-bar"></span>
                    <span class="menu-bar"></span>
                </label>

            </div>
        </div>
    </nav>
    <div v-else>
        <!--If not a user, show new -->
        <NoAuthNav/>
    </div>
</template>

<script>
    import NoAuthNav from './NoAuthNav'
    import Sidebar from '../Main/Sidebar'
    import {updated, version} from '../../../package'
    const $ = window.$    

    export default {
        name: 'Navigation',
        components: {NoAuthNav, Sidebar},
        data() {
            return {
                loading: false
            };
        },
        computed: {
            version() {
                return version + ' / ' + updated;
            },
            user() {
                return this.$store.getters.user;
            },
            admin_paths() {
                const paths = this.$store.getters.get_admin_paths;
                // loop through paths array and replace 'is_agent' with this.is_agent, etc
                // this is part of admin_paths refactor so it can be used by multiple nav components
                paths.map(x => {
                    switch(x.check._if) {
                        case 'is_agent':
                            x.check._if = this.is_agent;
                            break;
                        case 'is_driver':
                            x.check._if = this.is_driver;
                            break;
                        case 'is_admin':
                            x.check._if = this.is_admin;
                            break;
                        case 'is_super_admin':
                            x.check._if = this.is_super_admin;
                            break;
                        case 'is_finance':
                            x.check._if = this.is_finance;
                            break;
                    }
                })
                return paths
            },
            avatar() {
                return true;
            },
            avatar_url() {
                return this.$store.getters.get_user_avatar_url;
            },
            is_driver() {
                return this.$store.getters.is_driver;
            },
            is_agent() {
                return this.$store.getters.is_agent;
            },
            is_admin() {
                return this.$store.getters.is_admin;
            },
            is_super_admin() {
                return this.$store.getters.is_super_admin;
            },
            is_finance() {
                return this.$store.getters.is_finance;
            }
        },
        mounted() {
            $(document).ready(function () {
                if ($('.nav-item').children('.sub')) {
                    $('.nav-item').children('.sub').on('click', function () {
                        $('.toggle-menu').click();
                    });
                }
                $('.not-sub').on('click', function () {
                    $('.toggle-menu').click();
                });
            });
        },
        methods: {
            toggle_sub_menu() {
                if (this.$store.state.ui.sub_menu) {
                    this.$store.commit('hide_sub_menu');
                } else {
                    this.$store.commit('show_sub_menu');
                }
            },
            marketing_home() {
                window.location.href = 'https://www.healthyforlifemeals.com';
            },
            home() {
                if (this.user) {
                    this.$router.push('/dashboard');
                }
            },
            log_out() {
                this.$store.dispatch('log_out', this)
                .then(() => {
                    this.$router.push('/authenticate/welcome');
                });
            }
        }
    };
</script>

<style scoped lang="scss">
    @import '../../styles/scss/colors';

    nav {
        background: white;
        box-shadow: 1px 1px 1px 1px #EAEAEA;
        .icon {
            color: #79589F !important;

            font-size: 22px !important;
            width: auto;
            height: 50px;
            max-width: 100%;
            max-height: 100%;
            margin-right: 25px;
        }

        .navbar-brand {
            color: #79589F !important;
            text-transform: uppercase;
            letter-spacing: 2px;
            font-weight: 600;
            font-size: 14px;
        }
        a {
            color: #79589F !important;
        }
    }

    li {
        list-style: none;
    }

    .user-dropdown {
        top: 10px;
        .dropdown-menu {
            padding-top: 25px;
            top: 61px;
            left: 5px;
        }
    }

    .user-dropdown a {
        color: $dark-grey !important;
        position: relative;
        font-weight: 100;
        margin-right: 35px;
        display: flex;
        flex-direction: row;
        .avatar {
            position: relative;
            top: -10px;
            height: 50px;
            width: 50px;
            border-radius: 50%;
            margin-right: 15px;
        }
        p {
            margin-top: 8px;
        }
        &::after {
            display: none;
        }
    }

    .dropdown-toggle::after {
        margin-top: 0px;
        margin-left: 15px;
    }

    .order-btn {
        background: #2BDE73;
        color: white;
        margin-right: 25px;
        font-weight: 100;
    }

    .angle-down {
        position: relative;
        top: 3px;
        left: 10px;
        color: $grey;
    }

    .to-the-right {
        display: flex;
        button {
            height: 45px;
            margin-top: 10px;
        }
    }

    .dropdown-menu {
        border: none;
    }

    .toggle-menu {
        display: none;
    }

    .menu-bar {
        width: 30px;
        height: 4px;
        border-radius: 4px;
        background: #000;
        display: block;
        position: relative;
        transition: .20s ease-in-out;
        color: $purple;
        background: $purple;
    }

    label[for="toggle-menu"] {
        height: 24px;
        cursor: pointer;
        position: relative;
        // right:15px;
        top: 5px;
    }

    label[for="toggle-menu-2"] {
        height: 24px;
        cursor: pointer;
        position: relative;
        // right:15px;
        top: 5px;
    }

    .menu-bar:nth-child(1) {
        top: 0;
    }

    .menu-bar:nth-child(2) {
        top: 7px;
        left: 0;
    }

    .menu-bar:nth-child(3) {
        top: 14px;
    }

    .toggle-menu:checked + label[for="toggle-menu"] span:nth-child(1) {
        transform: rotate(135deg);
        top: 10px;
    }

    .toggle-menu:checked + label[for="toggle-menu"] span:nth-child(2) {
        opacity: 0;
        left: -50px;
    }

    .toggle-menu:checked + label[for="toggle-menu"] span:nth-child(3) {
        transform: rotate(-135deg);
        top: 4px;
    }

    .toggle-menu-2:checked + label[for="toggle-menu-2"] span:nth-child(1) {
        transform: rotate(135deg);
        top: 10px;
    }

    .toggle-menu-2:checked + label[for="toggle-menu-2"] span:nth-child(2) {
        opacity: 0;
        left: -50px;
    }

    .toggle-menu-2:checked + label[for="toggle-menu-2"] span:nth-child(3) {
        transform: rotate(-135deg);
        top: 4px;
    }

    .menu-bar-2 {
        width: 30px;
        height: 4px;
        border-radius: 4px;
        background: #000;
        display: block;
        position: relative;
        transition: .20s ease-in-out;

    }

    label[for="toggle-menu-2"] {
        height: 24px;
        cursor: pointer;
    }

    .menu-bar-2:nth-child(1) {
        top: 0;
    }

    .menu-bar-2:nth-child(2) {
        top: 7px;
    }

    .menu-bar-2:nth-child(3) {
        top: 14px;
    }

    .toggle-menu-2:checked + label[for="toggle-menu-2"] span:nth-child(1) {
        transform: rotate(45deg);
        top: 10px;
    }

    .toggle-menu-2:checked + label[for="toggle-menu-2"] span:nth-child(2) {
        opacity: 0;
        transform: rotate(-90deg);
    }

    .toggle-menu-2:checked + label[for="toggle-menu-2"] span:nth-child(3) {
        transform: rotate(-45deg);
        top: 4px;
    }

    .navbar-nav {
        margin-left: 15px;
    }

    .dropdown-item {
        padding: 0;
        margin-top: 0px;
        margin-left: 25px;
        &:hover {
            background: transparent;
            cursor: pointer;
        }
    }

    .sub .nav-link {
        margin-top: 0px;
        color: $purple;
    }

    .dropdown-item .icon-nav {
        margin-right: 15px;
    }

    .silver {
        color: $grey;
    }

    .user-dropdown .dropdown-menu {
        padding-top: 25px;
        top: 48px;
        left: 24px;
        border-radius: unset;
        border: 1px solid lightgrey;
    }

    .hflmLogo {
        height: 75px;
        position: absolute;
        left: 60px;
        image-rendering: auto;

    }

    @media(max-width: 500px) {
        .navbar-brand {
            margin-right: 2em;
        }
    }

    .button-9 {
        color: white !important;
        font-weight: 800;
        &:hover {
            background: $green-background;
            transition: all .5s ease-in-out;
        }
    }

    .capitalize {
        text-transform: capitalize;
        position: relative;
        top: 11px;
    }

    .user-circle {
        position: relative;
        top: -3px;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        margin-right: 15px;
        color: $grey;

    }

    .hflmLogo {
        &:hover {
            cursor: pointer;
        }
    }

    .icon {
        height: 50px;
    }

    .toggle-menu-2 {
        display: none;
    }

    .sub-menu {
        position: relative;
        top: 20px;
    }

    .log-in-mobile {
        position: absolute;
        right: 20px;
        top: 20px;
        border-radius: 50%;
        svg {
            padding-top: 2px;
        }
        &hover {
            color: $green;
        }
    }
</style>

