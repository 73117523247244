const MetricsSales  = () => import('@/components/Metrics/MetricsSales')
const MetricsChart  = () => import('@/components/Metrics/MetricsChart')
const MetricsActiveUsers  = () => import('@/components/Metrics/MetricsActiveUsers')
const MetricsUsersOrders  = () => import('@/components/Metrics/MetricsUsersOrders')
const MetricsCouponsUsed  = () => import('@/components/Metrics/MetricsCouponsUsed')

export const metrics_children = [
	{
		path: 'sales',
		name: 'MetricsRevenue',
		component: MetricsSales,
	},
	{
		path: 'chart',
		name: 'MetricsChart',
		component: MetricsChart,
	},
	{
		path: 'coupons',
		name: 'MetricsCouponsUsed',
		component: MetricsCouponsUsed,
	},
	{
		path: 'by-users',
		name: 'MetricsUsersOrders',
		component: MetricsUsersOrders,
	},
	{
		path: 'active-users',
		name: 'MetricsActiveUsers',
		component: MetricsActiveUsers,
	}
];
