<template>
	<div id="app" v-if="site_status_up">
		<lock-screen v-show="locked"/>
		<vue-snotify/>
		<loading-modal v-show="loading === true"/>
		<div id="content-wrapper">
			<navigation v-if="show_top_nav"/>
			<sub-menu v-if="user && sub_menu" class="d-none d-lg-block"/>
			<sub-nav v-if="show_left_nav" class="d-none d-lg-block"/>
			<transition mode="out-in" name="fade">
				<router-view/>
			</transition>
		</div>
		<footer-nav v-if="show_top_nav"/>
	</div>
	<div v-else>
		<maintenance/>
	</div>
</template>

<script>
	import FooterNav from './components/Main/FooterNav';
	import LoadingModal from './components/Globals/LoadingModal';
	import Navigation from './components/Main/Navigation';
	import SubNav from './components/Main/SubNav';
	import SubMenu from './components/Main/SubMenu';
	import LockScreen from './components/Globals/LockScreen';
	import moment from 'moment';
	import Maintenance from './components/Globals/Maintenance';

	export default {
		name: 'app',
		components: {
			Maintenance,
			FooterNav,
			LoadingModal,
			Navigation,
			SubNav,
			SubMenu,
			LockScreen
		},
		data() {
			return {
				is_dist: false
			};
		},
		updated() {
			this.check_patch();
			// if (this.user) {
			// 	trackJs.addMetadata('user_id', this.user.id);
			// 	trackJs.addMetadata('user_email', this.user.email);
			// 	// TODO: Token Expiration would be nice? Maybe? (this would identify 401 errors more easily)
			// }
		},
		methods: {
			check_patch() {
				let patchTime = moment(JSON.parse(localStorage.getItem('patchTime')));
				if (moment().isAfter(patchTime)) {
					this.$store.commit('set_patch_time');
					this.$store.dispatch('patch_user');
				}
			}
		},
		computed: {
			user() {
				return this.$store.getters.user;
			},
			sub_menu() {
				return this.$store.state.ui.sub_menu;
			},
			loading() {
				return this.$store.state.ui.loading || this.$store.state.ui.loader_semaphore > 0;
			},
			locked() {
				return this.$store.getters.is_locked;
			},
			site_status_up() {
				return this.$store.getters.get_site_status === 'up' || this.$store.getters.is_admin;
			},
			show_left_nav() {
				let routes = ['/confirm-email', '/reset-password', '/reset-password-request', '/reset-password-request', '/sign-up', '/authenticate/welcome', '/authenticate/log-in', '/authenticate/sign-up', '/authenticate/support', '/authenticate/confirm-email', '/authenticate/reset-password', '/authenticate/reset-password-request', '/help-find-a-plan', '/metrics/activity-level', '/get-started', '/metrics/goals', '/metrics/health-concerns', '/orders/choose-your-plan', '/orders/your-location', '/orders/pickup-or-delivery', '/orders/schedule', '/orders/submit-order', '/admin/reports/progress/printable', '/admin/reports/no-orders/printable', '/admin/reports/kitchen/printable', '/admin/reports/delivery/printable', '/admin/reports/route/printable', '/admin/reports/orders/printable', '/orders-receipt/printable', '/new-user', '/coming-soon', '/maintenance'];
				return !routes.includes(this.$route.path);
			},
			show_top_nav() {
				let routes = ['/sign-up', '/authenticate/welcome', '/authenticate/log-in', '/authenticate/sign-up', '/authenticate/support', '/authenticate/confirm-email', '/authenticate/reset-password', '/authenticate/reset-password-request', '/admin/reports/progress/printable', '/admin/reports/kitchen/printable', '/admin/reports/delivery/printable', '/admin/reports/route/printable', '/admin/reports/no-orders/printable', '/admin/reports/orders/printable', '/orders-receipt/printable', '/new-user', '/coming-soon', '/maintenance'];
				return !routes.includes(this.$route.path);
			}
		},
		created() {
			this.$store.commit('create_user');
			this.$store.dispatch('get_notifications');
			this.$store.dispatch('get_cutoff_times');
			this.$store.dispatch('get_delivery_offsets');
			this.$store.dispatch('get_site_status')
			.then(status => {
				this.$store.commit('set_site_status', status.status);
			})
			.catch(() => {
				this.$snotify.error('Error getting site status');
			});

		},
	};
</script>

<style lang="scss">
	@import './styles/scss/main';

	#app {
		display: flex;
		flex-direction: column;
		height: 100vh;
		background: white;
	}

	#content-wrapper {
		width: 100%;
		flex: 1 0 auto;
		margin-bottom: 55px;
	}
</style>
