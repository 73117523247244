<template>
	<div class="container">
		<div id="main-content">
			<img class="hflm-logo m-1" src="/static/HFLM_Logo_Vertical.png"/>
			<h2 class="m-1">Check Back Soon</h2>
			<h3 class="m-1">We are currently down for maintenance.</h3>
			<p class="m-1">Please pardon the inconvenience!</p>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Maintenance',
		data() {
			return {};
		},
		computed: {
			status() {
				return this.$store.getters.get_site_status;
			}
		}
	};
</script>

<style lang="scss" scoped>
	@import '../../styles/scss/fonts';
	@import '../../styles/scss/colors';

	@media only screen and (max-width: 320px) {

		.hflm-logo {
			max-width: 250px !important;
			height: 100%;
		}

	}

	.container {
		background-color: rgba(255, 255, 255, 0.2);
		position: absolute;
		max-width: 100%;
		height: 100%;
		margin: 0%;
		display: flex;
		justify-content: center;
	}

	#main-content {
		background-color: rgba(255, 255, 255, 0.7);
		width: auto;
		display: inline-block;
		margin: auto;
		text-align: center;
		padding: 5%;
	}

	.hflm-logo {
		max-width: 400px;
		width: auto;
		height: auto;
	}

</style>
