<template>
	<div class="main">
		<div class="row">
			<div class="metrics">
				<div class="row col-12 ml-2 d-flex justify-content-between">
					<p>Please choose a range below to get started.</p>
					<h2 v-if="start && end">{{start}} to {{end}}</h2>
					<div class="col-12 d-flex justify-content-between mb-4">
						<div class="d-flex choose-component">
							<vue-rangedate-picker v-on:selected="handle_range_picker" i18n="EN"/>
							<div class="ml-3">
								<time-picker
									:is-range="false"
									v-model="time1"
									range-separator="To"
									locale="en"
									format="h : mm a"
									v-on:change="withTime"
									:disabled="!start"
									start-placeholder="Start time"
									end-placeholder="End time">
								</time-picker>
							</div>
							<div class="ml-3">
								<time-picker
									:is-range="false"
									v-model="time2"
									range-separator="To"
									locale="en"
									format="h : mm a"
									:disabled="!start"
									v-on:change="withTime"
									start-placeholder="Start time"
									end-placeholder="End time">
								</time-picker>
							</div>
						</div>
					</div>
				</div>
				<tabs class="col-12 mt-2" :paths="metrics_paths" v-if="show_sidebar"/>
			</div>
		</div>
		<div class="row">
			<transition mode="out-in" name="fade">
				<router-view class="col-12 m-0 p-0"/>
			</transition>
		</div>
	</div>
</template>

<script>
	import Tabs from '../Main/Tabs';
	import VueRangedatePicker from 'vue-rangedate-picker';
	import moment from 'moment';
	import {TimePicker} from 'element-ui';
	import lang from 'element-ui/lib/locale/lang/en';
	import locale from 'element-ui/lib/locale';

	// configure language
	locale.use(lang);

	export default {
		name: 'Metrics',
		components: {
			Tabs,
			VueRangedatePicker,
			TimePicker
		},
		data() {
			return {
				loading: false,
				orderTotals: null,
				start: null,
				end: null,
				start_: null,
				end_: null,
				time1: moment().startOf('day').add(7, 'h'),
				time2: moment().endOf('day').subtract(4, 'h').add(1, 'm')
			};
		},
		computed: {
			metrics_paths() {
				return [
					{
						path: '/metrics/sales',
						name: 'Breakdown',
						style: 'sales',
						check: {_if: true}
					},
					{
						path: '/metrics/chart',
						name: 'Large Chart',
						style: 'chart',
						check: {_if: true}
					},
					// Removed because it was "comming soon" and who knows when that will be...
					// {
					// 	path: '/metrics/by-users',
					// 	name: 'User Information',
					// 	style: 'by-users',
					// 	check: {_if: true}
					// },
					{
						path: '/metrics/coupons',
						name: 'Coupons Used',
						style: 'coupons',
						check: {_if: true}
					},
					{
						path: '/metrics/active-users',
						name: 'Active Users',
						style: 'active-users',
						check: {_if: true}
					},
				];
			},
		},
		methods: {
			show_sidebar() {
				let routes = [];
				return !routes.includes(this.$route.path);
			},
			withTime() {
				const hours = moment(this.time1).hours();
				const minutes = moment(this.time1).minutes();
				const hours_ = moment(this.time2).hours();
				const minutes_ = moment(this.time2).minutes();
				const startWithHoursAndMinutes = moment(this.start_).utc().startOf('day').add(hours, 'h').add(minutes, 'm').toISOString();
				const endWithHoursAndMinutes = moment(this.end_).utc().startOf('day').add(hours_, 'h').add(minutes_, 'm').toISOString();
				this.get_totals(startWithHoursAndMinutes, endWithHoursAndMinutes);
			},
			handle_range_picker(e) {
				const {start, end} = e;
				this.graph_2000 = false;
				this.graph_1200 = false;
				this.graph_1500 = false;
				const hours = moment(this.time1).hours();
				const minutes = moment(this.time1).minutes();
				const hours_ = moment(this.time2).hours();
				const minutes_ = moment(this.time2).minutes();
				this.start_ = moment(start).subtract(1, 'd').toISOString();
				this.end_ = moment(end ? end : start).subtract(1, 'd').toISOString();
				const startWithHoursAndMinutes = moment(this.start_).utc().startOf('day').add(hours, 'h').add(minutes, 'm').toISOString();
				const endWithHoursAndMinutes = moment(this.end_).utc().startOf('day').add(hours_, 'h').add(minutes_, 'm').toISOString();
				this.start = moment(startWithHoursAndMinutes).format('dddd, MMMM Do YYYY');
				this.end = moment(endWithHoursAndMinutes ? endWithHoursAndMinutes : startWithHoursAndMinutes).format('dddd, MMMM Do YYYY');
				this.get_totals(startWithHoursAndMinutes, endWithHoursAndMinutes ? endWithHoursAndMinutes : startWithHoursAndMinutes);
			},
			get_totals(start, end) {
				this.$store.commit('set_loading_modal', {
					title: 'Please wait while we gather this information.',
					message: 'This may take a moment'
				});
				this.$store.commit('set_range', {
					start: start,
					end: end
				});
				this.$store.dispatch('get_report_order_totals_range', {
					start: start,
					end: end
				})
				.finally(() => {
					this.$store.commit('hide_loading_modal');
				});
			}
		}
	};
</script>
<style lang="scss">
	@import '../../styles/scss/_colors.scss';
	@import url("//unpkg.com/element-ui@2.4.6/lib/theme-chalk/index.css");

	.metrics {
		width: 100%;
		background: #FAFAFA;
		padding: 15px;
		border-radius: 10px;
		border: 1px solid #EAEAEA;
	}

	.end {
		display: flex;
		justify-content: flex-end;

	}

	.el-date-editor {
		border-radius: 25px;
		height: 36px;
		background: #FAFAFA;
	}

	.el-date-editor .el-range-input {
		font-size: 14px;
		font-weight: 600;
		color: $purple;
		background: #FAFAFA;
	}

	.el-date-editor .el-range-separator {
		padding: 0px 30px;
		line-height: 30px;
		color: #303133;
	}

	.el-time-spinner__item.active {
		color: $purple !important;
	}

	.el-time-panel__btn.confirm {
		color: $purple;
	}

	.get-data {
		margin-left: 15px;
		border-radius: 25px !important;
		border: 1px solid $grey !important;
	}
</style>

