export const payment_state = {
	payment: {
		coupons: [],
		cutoffs: null
	},
	order: {
		mealPlan: null,
		distributor: null,
		address: {
			address1: null,
			address2: null,
			city: null,
			state: null,
			zip: null
		},
		order: {
			coupon: null,
			startDate: null,
			weeks: null
		},
		paymentInfo: null
	}
};
