export const set_stripe_loader = (state, loading) => {
	if (state.ui.stripe_loading !== loading) {
		state.ui.stripe_loading = !state.ui.stripe_loading;
	}
};


export const set_coupons = (state, coupons) => {
	state.payment.coupons = coupons;
};

export const set_cutoff_times = (state, cutoffs) => {
	state.payment.cutoffs = {};
	cutoffs ? cutoffs.forEach(cutoff => {
		state.payment.cutoffs[cutoff.key] = JSON.parse(cutoff.value);
	}) : null;
};

export const add_to_order_meal_plan = (state, {mealPlan, order}) => {
	if (state.order.order === undefined) { //Joel what is going on here... at what point is our state different? Why?
		state.order.order = {
			coupon: null,
			startDate: null,
			weeks: null,
			subscribe: null,
			noFish: null
		};
	}
	state.order.mealPlan = mealPlan;
	state.order.order.startDate = order.startDate;
	state.order.order.weeks = order.weeks;
	state.order.order.subscribe = order.subscribe;
	state.order.order.noFish = order.noFish;
	sessionStorage.setItem('order', JSON.stringify(state.order));
};

export const add_to_order_distributor = (state, {distributor, delivery, address}) => {
	state.order.distributor = distributor;
	state.order.order.delivery = delivery;
	state.order.address = address;
	sessionStorage.setItem('order', JSON.stringify(state.order));
};


export const set_coupon = (state, coupon) => {
	state.order.order.coupon = coupon;
};

