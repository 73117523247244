<template>
    <div>
        <form>

            <div class="row justify-content-center">
                <div class="col-12 col-md-10">
                    <messages v-if="error" type="error" :message="error" icon="exclamation-circle"/>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-10 p-0 text-center">
                    <h1 class="d-none d-md-block">Welcome back. You look nice today!</h1>
                    <h2 class="d-md-none font-reduce">Welcome back. You look nice today!</h2>
                    <h3 v-if="reset_needed" class="is-danger">Hello! It looks like you are using an old password. Please
                        check
                        {{credentials.email ? credentials.email : 'your email'}}
                        to reset your password.</h3>
                </div>
            </div>

            <div class="row mt-3 justify-content-center px-lg-5">
                <div class="col-12 col-md-10 input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text"><icon fill="currentColor" name="envelope" class="icon"/></span>
                    </div>
                    <input id="email" type="email" class="form-control rounded-right" placeholder="Email" aria-label="Email"
                             v-model="credentials.email">
                </div>
            </div>

            <div class="row mt-3 justify-content-center px-lg-5">
                <div class="col-12 col-md-10 input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text"><icon fill="currentColor" name="lock" class="icon"/></span>
                    </div>
                    <input id="password" type="password" class="form-control rounded-right" placeholder="Password"
                             aria-label="Password"
                             v-model="credentials.password">
                </div>
            </div>

            <div class="row mt-3 justify-content-center px-lg-5">
                <div class="col-12 col-md-10">
                    <button v-on:click.prevent="log_in" class="btn btn-primary w-100">Log In</button>
                </div>
            </div>

            <div class="row justify-content-center mt-4">
                <div class="col-12 col-md-10 text-center">
        <span style="font-weight:100;">
          New to Healthy For Life Meals?
        </span>
                    <br/>
                    <router-link to="/authenticate/sign-up" class="font-weight-bold">Sign Up Today</router-link>
                </div>
            </div>

            <div class="row justify-content-around px-lg-5 my-5">
                <router-link class="" to="/authenticate/welcome">Back</router-link>
                <router-link to="/authenticate/reset-password-request" class="link">Forgot Password?</router-link>
            </div>

        </form>
    </div>

</template>

<script>
    const $ = window.$    
    import Messages from '../Globals/Messages';

    export default {
        components: {
            Messages
        },
        name: 'auth-log-in',
        computed: {
            user() {
                return this.$store.getters.user;
            }
        },
        data() {
            return {
                reset_needed: false,
                credentials: {
                    email: '',
                    password: ''
                },
                error: null
            };
        },
        methods: {
            log_in_finished(user) {
                if (user) {
                    if (this.$route.name === 'AuthLogInSupport') {
                        let route = 'https://healthyforlifemeals.zendesk.com/access/jwt?jwt=' + user.supportToken;
                        let return_to = this.$route.query.return_to;
                        if (return_to != null && return_to.length > 0) {
                            route += '&return_to=' + return_to;
                        }
                        window.location.href = route;
                    } else {
                        this.$router.push('/dashboard');
                    }
                }
            },
            log_in() {
                let valid = true;
                if (this.credentials.email.length < 4) {
                    valid = false;
                    $('#email').addClass('is-danger');
                    setTimeout(() => {
                        $('#email').removeClass('is-danger');
                    }, 2500);
                }
                if (this.credentials.password.length < 8) {
                    valid = false;
                    $('#password').addClass('is-danger');
                    setTimeout(() => {
                        $('#password').removeClass('is-danger');
                    }, 2500);
                }

                if (valid) {
                    this.$store.dispatch('log_in', this.credentials).then(response => {
                        if (response.error) {
                            if (response.error === 'reset') {
                                this.reset_needed = true;
                            } else {
                                this.reset_needed = false;
                                this.error = response.error;
                                setTimeout(() => {
                                    this.error = null;
                                }, 5000);
                            }
                        } else {
                            this.log_in_finished(response.user);
                        }
                    })
                    .catch(error => {
                        this.error = error.message;
                        setTimeout(() => {
                            this.error = null;
                        }, 5000);
                    });
                }
            }
        },
        mounted() {
            if (this.$route.name !== 'AuthLogInSupport') {
                this.log_in_finished(this.user);
            }
        }
    };

</script>

<style scoped lang="scss">
    @import '../../styles/scss/colors';

    .icon {
        color: $grey;
        width: 40px;
    }

    /* Change the white to any color ;) */
    input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px white inset;
    }

    .link {
        color: $purple !important;
        font-weight: bold;
        &:hover {
            cursor: pointer;
        }
    }

</style>
