<template>
	<nav id="subnav" class="subnav">
		<ul class="subnav-menu container-main">
			<router-link class="subnav-item dashboard-path" to="/dashboard">Dashboard</router-link>
			<router-link class="subnav-item account-path" to="/account">Account</router-link>
			<router-link class="subnav-item order-path" to="/orders/choose-your-plan">Order Now</router-link>
			<router-link v-if="is_agent || is_driver" class="subnav-item admin-path" to="/admin">Administrative</router-link>
			<router-link v-if="is_super_admin" class="subnav-item metrics-path" to="/metrics/sales">Metrics</router-link>
			<!--<router-link v-if="is_super_admin" class="subnav-item documentation-path" to="/documentation">API</router-link>-->
			<div class="ml-5 is-danger font-weight-bold" v-if="show_warning">
				YOU ARE ON THE {{ env.toUpperCase() }} SERVER 
			</div>
			<div class="ml-5">
				<!--<icon fill="currentColor" name="spinner" spin class="subnav-item" v-show="api_call_in_progress"/>-->
			</div>
		</ul>
	</nav>
</template>
<script>
	const $ = window.$
	
	export default {
		name: 'SubNav',
		computed: {
			user() {
				return this.$store.getters.user;
			},
			is_driver() {
				return this.$store.getters.is_driver;
			},
			is_agent() {
				return this.$store.getters.is_agent;
			},
			is_super_admin() {
				return this.$store.getters.is_super_admin;
			},
			api_call_in_progress() {
				return this.$store.getters.is_agent && this.$store.getters.is_fetching;
			},
			show_warning() {
				return process.env.NODE_ENV !== 'production'
			},
			env() {
				return process.env.NODE_ENV
			}
		},
		mounted() {
			this.$nextTick(function () {
				let top = this.$route.path.split('/').filter(x => x !== '');
				if (this.$route.name === 'Home') {
					$(`.dashboard-path`).addClass('clicked');
				}
				$(`.${top[0]}-path`).addClass('clicked');
			});
		},
		watch: {
			'$route': function () {
				this.$nextTick(function () {
					$('.subnav-item').removeClass('clicked');
					let top = this.$route.path.split('/').filter(x => x !== '');
					$(`.${top[0]}-path`).addClass('clicked');
				});
			}
		}
	};
</script>

<style scoped lang="scss">
	@import '../../styles/scss/colors';

	.container-main {
		margin-left: 8%;
		margin-right: 8%;
	}

	.subnav {
		height: 65px;
		background: $light-silver;
		line-height: 65px;
		border-top: .5px solid $silver;
		border-bottom: .5px solid $silver;
	}

	.subnav-menu {
		display: flex;
	}

	.subnav-item:not(:first-child) {
		margin-left: 55px;
	}

	.subnav-item {
		list-style: none;
		color: $grey;
		font-size: 14px;
		&:hover {
			cursor: pointer;
			text-decoration: none;
		}
		&.clicked {
			height: 63px;
			color: $purple;
			border-bottom: 2px solid $purple;
		}
	}

	ul.subnav-menu.container-main {
		padding: 0;
	}
</style>
