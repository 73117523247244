export const set_meals = (state, meals) => {
	state.meals.meals = (meals || []).sort((a, b) => {
		return a.name > b.name;
	});
};

export const set_meal_plans = (state, meal_plans) => {
	state.meals.meal_plans = meal_plans;
};

export const set_meal_plan_infos = (state, meal_plan_infos) => {
	state.meals.meal_plan_infos = meal_plan_infos;
};

export const set_meal_plans_meals = (state, plans_meals) => {
	state.meals.meal_plans_meals = plans_meals;
};

export const set_meal = (state, meal) => {
	let existing = state.meals.meals.find(element => element.id === meal.id);
	if (existing != null) {
		Object.assign(existing, meal);
	} else {
		state.meals.meals.push(meal);
	}
	state.meals.meals.sort((a, b) => {
		return a.name > b.name;
	});
};

export const set_likes = (state, likes) => {
	state.meals.likes = likes;
};

export const set_delivery_offsets = (state, delivery_offsets) => {
	state.meals.delivery_offsets = delivery_offsets;
};
