import * as Api from '../api';

export const patch_user = ({commit, getters}) => {
	return Api.get({
		commit: commit,
		endpoint: `users/${getters.get_user_id}`,
		token: getters.token
	}).then(response => {
		if (response.error) {
			console.log(response.error)
		} else if (response.user) {
			commit('set_user', response.user);
			commit('create_user');
		}
		return response;
	}).catch(error => {
		console.log(error)
	});
};

export const update_user = ({commit, dispatch, getters}, {update, doPatch = true}) => {
	return Api.post({
		commit: commit,
		endpoint: `users/${update.id}`,
		body: update,
		token: getters.token
	}).then(response => {
		if (doPatch) {
			dispatch('patch_user');
		}
		return response;
	});
};

export const add_address = ({commit, dispatch, getters}, {userId, address, doPatch = true}) => {
	return Api.post({
		commit: commit,
		endpoint: `users/${userId}/addresses`,
		token: getters.token,
		body: address
	}).then(result => {
		if (doPatch) {
			dispatch('patch_user');
		}
		return result;
	});
};

export const update_address = ({commit, dispatch, getters}, {userId, address, doPatch = true}) => {
	return Api.update({
		commit: commit,
		endpoint: `users/${userId}/addresses/${address.id}`,
		token: getters.token,
		body: address
	}).then(result => {
		if (doPatch) {
			dispatch('patch_user');
		}
		return result;
	});
};

export const delete_address = ({commit, dispatch, getters}, {userId, address, doPatch = true}) => {
	return Api.destroy({
		commit: commit,
		endpoint: `users/${userId}/addresses/${address.id}`,
		token: getters.token
	}).then(result => {
		if (doPatch) {
			dispatch('patch_user');
		}
		return result;
	});
};

export const update_user_weight = ({commit, dispatch, getters}, {id, weight, doPatch = true}) => {
	return Api.post({
		commit: commit,
		endpoint: `users/${id}/weights/${weight}`,
		token: getters.token
	}).then(response => {
		if (doPatch) {
			dispatch('patch_user');
		}
		return response;
	});
};

export const get_recent_order_payment_info = ({commit, getters}, orderId) => {
	return Api.get({
		commit: commit,
		endpoint: `payments/payment/${orderId}`,
		token: getters.token
	});
};

export const cancel_subscription = ({commit, dispatch, getters}, {userId, subId, orders, allOrders = false, doPatch = true}) => {
	return Api.destroy({
		commit: commit,
		endpoint: `payments/subscriptions/${userId}/${subId}`,
		token: getters.token,
		body: {
			orders: orders,
			allOrders: allOrders
		}
	}).then(response => {
		if (!response.error) {
			if (doPatch) {
				dispatch('patch_user');
			}
		}
		return response;
	});
};

export const save_avatar_image = ({commit, dispatch, getters}, formData) => {
	return Api.post({
		commit: commit,
		endpoint: `users/avatar/${getters.get_user_id}/image`,
		token: getters.token,
		body: formData
	}).then(() => {
		dispatch('patch_user');
	});
};
