<template>
	<nav class="subnav">
		<ul class="subnav-menu container-main justify-content-end">
			<a class="subnav-item" v-on:click.prevent="plan">Help Find a Plan
				<icon fill="currentColor" name="info-circle" class="icon"/>
			</a>
			<a class="subnav-item" v-on:click.prevent="order">Order Now
				<icon fill="currentColor" name="shopping-cart" class="icon"/>
			</a>
			<a class="subnav-item" v-on:click.prevent="home">Dashboard
				<icon fill="currentColor" name="home" class="icon"/>
			</a>
			<a class="subnav-item" v-on:click.prevent="get_help">Get Help
				<icon fill="currentColor" name="question-circle" class="icon"/>
			</a>
			<a class="subnav-item clicked" v-on:click.prevent="log_out">Log Out
				<icon fill="currentColor" name="sign-out-alt" class="icon"/>
			</a>
		</ul>
	</nav>
</template>

<script>
	const $ = window.$

	export default {
		name: 'sub-menu',
		methods: {
			log_out() {
				$('.toggle-menu-2').click();
				this.$store.commit('hide_sub_menu');
				this.$store.dispatch('log_out')
				.then( () => {
					this.$router.push('/authenticate/welcome');
				})
				.catch( () => {
					this.$store.commit('toggle_loader');
				});
			},
			get_help() {
				$('.toggle-menu-2').click();
				this.$store.commit('hide_sub_menu');
				window.location.href = 'https://support.healthyforlifemeals.com';
			},
			plan() {
				$('.toggle-menu-2').click();
				this.$store.commit('hide_sub_menu');
				this.$router.push('/help-find-a-plan');
			},
			order() {
				$('.toggle-menu-2').click();
				this.$store.commit('hide_sub_menu');
				this.$router.push('/orders/choose-your-plan');
			},
			home() {
				$('.toggle-menu-2').click();
				this.$store.commit('hide_sub_menu');
				this.$router.push('/dashboard');
			}
		}
	};
</script>

<style scoped lang="scss">
	@import '../../styles/scss/colors';

	.container-main {
		margin-left: 8%;
		margin-right: 8%;
	}

	.subnav {
		height: 65px;
		background: white;
		line-height: 65px;
		box-shadow: 2px 2px 2px $purple-background-2;
		transition: height 1s ease-in;
	}

	.subnav-menu {
		display: flex;
	}

	.subnav-item:not(:first-child) {
		margin-left: 55px;
	}

	.subnav-item {
		list-style: none;
		color: $grey;
		font-size: 14px;
		&:hover {
			cursor: pointer;
			text-decoration: none;
		}
		&.clicked {
			height: 63px;
		}
	}

	ul.subnav-menu.container-main {
		padding: 0;
	}

	a {
		color: $grey !important;
		&:hover {
			text-decoration: none;
			color: $purple !important;
		}
	}

	.router-link-exact-active, .router-link-active {
		text-decoration: none;
	}

	.icon {
		position: relative;
		top: 3px;
		left: 7px;

	}

</style>
