<template>
    <div>
        <div>
            <div class="tabs-container">
                <li class="nav-item" v-for="(path,i) in paths" :key='i'>
                    <router-link :to="path.path" :class="`${path.style}-path`" class="sidebar-item ml-2"
                                     data-toggle="tab"
                                     v-if="path.check ? path.check._if : true">
                        {{path.name}}
                    </router-link>
                </li>
            </div>
        </div>
    </div>
</template>

<script>
    const $ = window.$    
    
    export default {
        name: 'tabs',
        props: ['paths', 'title', 'version'],
        mounted() {
            let top = this.$route.path.split('/').filter(x => x !== '');
            if (top.length === 1) {
                $(`.sidebar-item.${this.paths[0].style}-path`).addClass('clicked');
            } else {
                $(`.sidebar-item.${top[1]}-path`).addClass('clicked');
            }
        },
        watch: {
            '$route': function () {
                this.$nextTick(function () {
                    let top = this.$route.path.split('/').filter(x => x !== '');
                    if (top.length === 1) {
                        $(`.sidebar-item.${this.paths[0].style}-path`).addClass('clicked');
                        $(`.sidebar-item.${top[0]}-path`).addClass('clicked');
                    } else {
                        $(`.sidebar-item.${top[1]}-path`).addClass('clicked');
                    }
                });
            }
        }
    };
</script>

<style lang="scss">
    @import '../../styles/scss/colors';

    .tabs-container {
        display: flex;
        list-style: none;
        justify-content: center;
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: -10px;
    }

    .sidebar-item {
        list-style: none;
        text-decoration: none;
        color: #777;
        font-weight: 200;
        padding: .375rem .75rem;
        &.clicked {
            color: $purple;
            border-bottom: 3px solid $purple;
            font-weight: 400;
            white-space: nowrap;
            vertical-align: middle;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            padding: .375rem .75rem;
            font-style: italic;
            transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
        }
    }
</style>
