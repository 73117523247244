<template>
    <div class="container">

        <form class="signup" action="#" method="post" v-on:submit.prevent="validateBeforeSubmit">

            <div class="row mt-3" :class="{ 'control': true }">
                <div class="col-12 input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">First Name</span>
                    </div>
                    <input class="form-control"
                             type="text"
                             v-model="user.firstName"
                             name="First Name"
                             id="firstName"
                             v-validate="{'required': true}"
                             :class="{'input': true, 'is-danger': errors.has('First Name')}"/>
                </div>
            </div>


            <div class="row mt-3" :class="{ 'control': true }">
                <div class="col-12 input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Last Name</span>
                    </div>
                    <input class="form-control"
                             type="text"
                             v-model="user.lastName"
                             name="Last Name"
                             id="lastName"
                             v-validate="{'required': true}"
                             :class="{'input': true, 'is-danger': errors.has('Last Name')}"/>
                </div>
            </div>


            <div class="row mt-3" :class="{ 'control': true }">
                <div class="col-12 input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Email</span>
                    </div>
                    <input class="form-control"
                             type="text"
                             v-model="user.email"
                             name="E-Mail"
                             id="email"
                             data-vv-validate-on="none"
                             v-validate="{'required': !adminCreate, 'email': true}"
                             :class="{'input': true, 'is-danger': errors.has('E-Mail')}"/>
                </div>
                <div class="col-12 text-right">
                    <span v-if="errors.has('E-Mail')" class="is-danger">{{errors.first('E-Mail') }}</span>
                </div>
            </div>

            <div class="row mt-3" :class="{ 'control': true }">
                <div class="col-12 input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Phone</span>
                    </div>
                    <masked-input class="form-control"
                                      type="tel"
                                      v-model="user.phoneHome"
                                      name="Phone"
                                      id="Phone"
                                      data-vv-validate-on="none"
                                      v-validate="{required: true, regex: /^\(\d\d\d\) \d\d\d\-\d\d\d\d$/}"
                                      :mask="['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]"
                                      :class="{'input': true, 'is-danger': errors.has('Phone')}"/>
                    <div class="col-12 text-right">
                        <span v-if="errors.has('Phone')" class="is-danger">{{errors.first('Phone') }}</span>
                    </div>
                </div>
            </div>

            <div class="row mt-3" :class="{ 'control': true }" v-if="!adminCreate">
                <div class="col-12 input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Password</span>
                    </div>
                    <input class="form-control"
                             type="password"
                             v-model="user.password"
                             name="Password"
                             id="password"
                             data-vv-validate-on="none"
                             v-validate="{'required': !adminCreate, 'min': 8}"
                             :class="{'input': true, 'is-danger': errors.has('Password')}"/>
                </div>
                <div class="col-12 text-right">
                    <span v-if="errors.has('Password')" class="is-danger">{{errors.first('Password') }}</span>
                </div>
            </div>


            <div class="text-center mt-3" v-if="!adminCreate">
                <p>By signing up, you agree to the Healthy For Life Meals<br/>
                    <a href="https://www.healthyforlifemeals.com/terms-of-service" target="_blank">Terms of Service</a>
                    & <a href="https://www.healthyforlifemeals.com/privacy-policy" target="_blank">Privacy Policy</a>
                </p>
            </div>

            <div class="row mt-3">
                <div class="col-12">
                    <button id="signUpButton" class="btn btn-primary w-100">
                        {{loading ? 'Creating Account' : adminCreate ? 'Create Account' : 'Sign Up'}}
                        <icon fill="currentColor" name="spinner" spin class="loading-icon ml-1" v-if="loading"/>
                    </button>
                </div>
            </div>

        </form>
    </div>
</template>

<script>

    import MaskedInput from 'vue-text-mask';
    const $ = window.$    

    export default {
        components: {
            MaskedInput
        },
        props: ['adminCreate'],
        data() {
            return {
                loading: false,
                user: {
                    firstName: null,
                    lastName: null,
                    email: null,
                    phoneHome: null,
                    password: null
                }
            };
        },
        methods: {
            sign_up() {
                this.loading = true;
                $('#signUpButton').prop('disabled', true);

                if (this.adminCreate) {
                    this.user.password = (Math.random() + 1).toString(36);
                    if (!this.user.email || this.user.email.length < 5) {
                        this.user.email = (Math.random() + 1).toString().substr(2) + '@hflm.com';
                    }
                }
                this.$store.dispatch('sign_up', {
                    user: this.user,
                    adminCreate: this.adminCreate
                })
                .then(result => {
                    this.loading = false;
                    $('#signUpButton').prop('disabled', false);
                    if (result.error) {
                        this.$eventHub.$emit('show-error', result.error);
                    } else {
                        this.user.firstName = null;
                        this.user.lastName = null;
                        this.user.email = null;
                        this.user.phoneHome = null;
                        this.user.password = null;
                        this.$emit('finished');
                    }
                })
                .catch(error => {
                    this.loading = false;
                    this.$eventHub.$emit('show-error', error);
                    $('#signUpButton').prop('disabled', false);
                });
            },
            validateBeforeSubmit() {
                this.$validator.validateAll()
                .then(valid => {
                    if (valid) {
                        this.sign_up();
                    }
                });
            }
        }
    };
</script>

<style lang="scss" scoped>

    .loading-icon {
        position: relative;
        vertical-align: center;
        top: 2px;
    }

    .input-group-text {
        width: 100px;
    }

    @media(max-width: 450px) {
        .input-group-text {
            width: auto;
        }
    }

    /* Change the white to any color ;) */
    input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px white inset;
    }

    .input::placeholder {
        color: lightgray;
    }

</style>
