<template>
	<div class="modal vertical-center">
		<form class='w-100'>
		<div class="row">
			<icon fill="currentColor" name="lock" class="lock col-12 mb-2"/>
			<div class="col-12 d-flex justify-content-around">
				<input class="col-6 col-lg-3 form-control" type="password" autocomplete="new-password" v-model="password"/>
			</div>
		</div>
		</form>
	</div>

</template>

<script>
	export default {
		name: 'lock-screen',
		data() {
			return {
				password: null
			};
		},
		watch: {
			password: function () {
				if (this.password === 'loseweight') {
					this.$store.dispatch('unlock_ui');
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	@import '../../styles/scss/main';

	.modal {
		background-color: rgba(255, 255, 255, 1);
	}

	.lock {
		color: black;
		height: 50px;
	}

	.vertical-center {
		min-height: 100vh;
		display: flex;
		align-items: center;
	}

</style>
