export const create_user = (state) => {
	try {
		if (localStorage.getItem('user')) {
			let user = JSON.parse(localStorage.getItem('user'));
			state.user = user;
		} else {
			state.user = null;
		}
	} catch (error) {
		state.user = null;
	}
};

export const set_user = (state, user) => {
	localStorage.setItem('user', JSON.stringify(user));
	state.user = user;
};

export const remove_user = (state) => {
	localStorage.removeItem('user');
	state.user = null;
};
