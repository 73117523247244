<template>
	<div key="1" class="pl-lg-5 pr-lg-5">
		<div class="row mt-1 pt-1 justify-content-center">
			<div class="col-xl-10 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
				<h1 class="starting">Welcome{{user ? user.firstName ? ', ' + user.firstName + '.' : '!' : ', Friend!'}}</h1>
				<p class="starting-text">
					To get started, select a meal plan. Need help? No problem.
					We can help you find the plan to best suit you. You can edit or cancel your plan at any time.
				</p>
			</div>
		</div>

		<div class="row justify-content-center">
			<div class="col-10 col-md-3 mt-3">
				<router-link to="/orders/choose-your-plan" class="btn btn-outline-primary w-100">Order Now</router-link>
			</div>
			<div class="col-10 col-md-6 mt-3">
				<router-link to="/help-find-a-plan" class="btn btn-primary w-100">Help Me Find A Plan</router-link>
			</div>
			<div class="col-10 col-md-3 mt-3">
				<router-link to="/authenticate/log-in" class="btn btn-outline-primary w-100">Log In</router-link>
			</div>
		</div>

		<div class="row justify-content-center mt-4">
			<div class="col-10 text-center">
        <span style="font-weight:100;">
          New to Healthy For Life Meals?
        </span>
				<br/>
				<router-link to="/authenticate/sign-up" class="font-weight-bold">Sign Up Today</router-link>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'auth-welcome',
		computed: {
			user() {
				return this.$store.getters.user;
			}
		}
	};
</script>
