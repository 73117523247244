import * as Api from '../api';

export const get_cards_for_user = ({commit, getters}, {userId, distributorId}) => {
	if (!distributorId) {
		alert('NEED DISTRIBUTOR ID');
	}
	return Api.get({
		commit: commit,
		endpoint: `payments/cards/${userId}/${distributorId}`,
		token: getters.token
	});
};

export const get_charges_for_user = ({commit, getters}, {userId, distributorId}) => {
	if (!distributorId) {
		alert('NEED DISTRIBUTOR ID');
	}
	return Api.get({
		commit: commit,
		endpoint: `payments/charges/${userId}/${distributorId}`,
		token: getters.token
	});
};

export const add_card_to_user = ({commit, getters}, {userId, distributorId, cardToken}) => {
	if (!distributorId) {
		alert('NEED DISTRIBUTOR ID');
	}
	return Api.post({
		commit: commit,
		endpoint: `payments/cards/${userId}/${distributorId}`,
		token: getters.token,
		body: cardToken
	});
};

export const delete_card_from_user = ({commit, getters}, {userId, distributorId, cardId}) => {
	if (!distributorId) {
		alert('NEED DISTRIBUTOR ID');
	}
	return Api.destroy({
		commit: commit,
		endpoint: `payments/cards/${userId}/${distributorId}/${cardId}`,
		token: getters.token
	});
};

export const stripe_connect = ({commit, getters}, payload) => {
	return Api.post({
		commit: commit,
		endpoint: 'payments/stripe/connect/distributors',
		token: getters.token,
		body: payload
	});
};

export const get_cutoff_times = ({commit}) => {
	return Api.get({
		commit: commit,
		endpoint: `payments/orders/cutoffs`
	}).then(result => {
		if (result.error) {
			// todo: handle error
		} else {
			commit('set_cutoff_times', result.cutoffs);
		}
		return result;
	}).catch(() => {
		// todo: handle error
	});
};

export const submit_order = ({commit, getters}, body) => {
	return Api.post({
		commit: commit,
		endpoint: `payments/orders/${getters.get_user_id}`,
		token: getters.token,
		body: body
	});
};

export const submit_order_admin = ({commit, getters}, payload) => {
	return Api.post({
		commit: commit,
		endpoint: `payments/orders/${payload.user.id}`,
		token: getters.token,
		body: payload
	});
};

export const submit_schedule_subscription_admin = ({commit, getters}, payload) => {
	return Api.post({
		commit: commit,
		endpoint: `payments/subscriptions/${payload.user.id}/schedule`,
		token: getters.token,
		body: payload
	});
};

export const check_coupon_admin = ({commit, getters}, body) => {
	return Api.post({
		commit: commit,
		endpoint: 'payments/coupons/check',
		body: body,
		token: getters.token,
	}).then(response => {
		return response;
	});
};

export const check_coupon = ({commit, getters}, body) => {
	return Api.post({
		commit: commit,
		endpoint: 'payments/coupons/check',
		body: body,
		token: getters.token,
	}).then(response => {
		if (response.error) {
			commit('set_coupon', null);
		}
		commit('set_coupon', response.coupon);
		return response;
	});
};

export const add_to_order_meal_plan = ({commit}, payload) => {
	return new Promise((resolve) => {
		commit('add_to_order_meal_plan', payload);
		resolve(payload);
	});
};

export const add_to_order_distributor = ({commit}, payload) => {
	return new Promise((resolve) => {
		commit('add_to_order_distributor', payload);
		resolve(payload);
	});
};

export const update_subscription = ({commit, getters}, {userId, subId, subscription}) => {
	return Api.update({
		commit: commit,
		endpoint: `payments/subscriptions/${userId}/${subId}`,
		token: getters.token,
		body: {update: subscription}
	});
};

export const cancel_order_admin = ({commit, getters}, orderId) => {
	return Api.destroy({
		commit: commit,
		endpoint: `admin/cancel-order/${orderId}`,
		token: getters.token,
		body: {}
	});
};
