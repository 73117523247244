export const get_order = state => {
    if (state.order.mealPlan === null) {
        state.order = JSON.parse(sessionStorage.getItem('order'));
    }
    return state.order;
};

export const get_active_order_items = (state) => {
    if (state.user && state.user.orders) {
        return state.user.orders.reduce((acc, order) => acc.concat(order.orderItems), [])
                        .filter(item => item.stage !== 'cancelled');
    }
    return [];
};

export const check_order_dates = (state, getters) => {
    if (getters.get_all_orders) {
        return getters.get_all_orders
                          .map(x => x.orderItems)
                          .reduce((a, b) => a.concat(b), [])
                          .filter(y => y.stage !== 'cancelled');
    }
    return [];
};
