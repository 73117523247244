<template>
	<nav class="navbar navbar-expand-lg navbar-light d-flex justify-content-between">
		<img class="hflmLogo d-none d-lg-block" src="/static/HFLM_Logo_Horizontal.png" v-on:click="marketing_home"/>
		<img class="hflmLogo-mobile icon d-block d-lg-none" src="/static/HFLM_Logo_Horizontal.png" v-on:click="marketing_home"/>

		<div class="d-flex">
			<a class="nav-item mr-5" v-on:click.prevent="$router.push('/help-find-a-plan')">Help Find a Plan
			</a>
			<a class="nav-item mr-5" v-on:click.prevent="get_help">Get Help
			</a>
			<a class="nav-item mr-5" v-on:click.prevent="$router.push('/authenticate/welcome')">Login/Signup
			</a>
		</div>
	</nav>
</template>

<script>
	const $ = window.$
	
	export default {
		name: 'NoAuthNav',
		computed: {
			user() {
				return this.$store.getters.user;
			},
		},
		mounted() {
		},
		methods: {
			get_help() {
				$('.toggle-menu-2').click();
				this.$store.commit('hide_sub_menu');
				window.location.href = 'https://support.healthyforlifemeals.com';
			},
			marketing_home() {
				window.location.href = 'https://www.healthyforlifemeals.com';
			},
		}
	};
</script>

<style scoped lang="scss">
	@import '../../styles/scss/fonts';
	@import '../../styles/scss/colors';

	nav {
		background: white;
		box-shadow: 1px 1px 1px 1px #EAEAEA;

		.navbar-brand {
			color: #79589F !important;
			text-transform: uppercase;
			letter-spacing: 2px;
			font-weight: 600;
			font-size: 14px;
		}
		a {
			color: #79589F !important;
		}
	}

	li {
		list-style: none;
	}

	.hflmLogo {
		height: 75px;
		left: 60px;
		image-rendering: auto;
		&:hover {
			cursor: pointer;
		}
	}

	.nav-item {
		&:hover {
			cursor: pointer;
		}
	}

	.nav-item:last-child {
		font-weight: bold;
	}

</style>
