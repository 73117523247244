import * as Api from '../api.js';

export const sign_up = ({commit}, {user, adminCreate}) => {
	return Api.post({
		commit: commit,
		endpoint: `users/sign-up`,
		body: user
	})
	.then(result => {
		if (result.error) {
			// do nothing
		} else {
			if (!adminCreate) {
				commit('set_user', result.user);
			}
		}
		return result;
	})
};

export const log_in = ({commit}, user) => {
	commit('loader_acquire');
	return Api.post({
		commit: commit,
		endpoint: `users/log-in`,
		body: user
	})
	.then(result => {
		commit('loader_release');
		if (result.error) {
			commit('loader_release');
			console.log(result.error);
		} else {
			commit('set_user', result.user);
			commit('set_patch_time');
		}
		return result;
	})
	.catch(() => {
		commit('loader_release');
	});
};

export const log_out = ({commit, getters}) => {
	commit('loader_acquire');
	return Api.post({
		commit: commit,
		endpoint: `users/log-out`,
		token: getters.token
	})
	.then(response => {
		commit('loader_release');
		if (response.error) {
			// do nothing
		} else {
			Promise.all([
			commit('remove_patch_time'),
			commit('remove_user', response)])
			.then(() => location.reload(true))
		}
	})
	.catch(() => {
		commit('loader_release');
	});
};

export const refresh_token = ({commit, getters}) => {
	return Api.get({
		commit: commit,
		endpoint: `users/${getters.get_user_id}/refresh-token`,
		token: getters.token
	})
};

export const log_out_everywhere = ({commit, getters}, {userId}) => {
	return Api.get({
		commit: commit,
		endpoint: `users/${userId}/logout-all`,
		token: getters.token
	})
	.then(response => {
		if (response.error) {
			// do nothing
		} else {
			commit('remove_user', response);
		}
	})
};

export const confirm_email = ({commit}, {...body}) => {
	return Api.post({
		commit: commit,
		endpoint: 'users/confirm-email',
		body: body
	});
};

export const reset_password = ({commit}, payload) => {
	return Api.post({
		commit: commit,
		endpoint: 'users/reset-password',
		body: payload
	})
	.then(result => {
		if (result.error) {
			//  todo: handle error
		} else {
			commit('set_user', result.user);
		}
		return result;
	});
};

export const reset_password_request = ({commit}, payload) => {
	return Api.post({
		commit: commit,
		endpoint: 'users/reset-password-request',
		body: payload
	});
};

export const update_password = ({commit, getters}, {id, passwords}) => {
	return Api.post({
		commit: commit,
		endpoint: `users/${id}/password`,
		token: getters.token,
		body: passwords
	})
};

