import AuthLogIn from '@/components/Auth/AuthLogIn';
import AuthLogOut from '@/components/Auth/AuthLogOut';
import AuthWelcome from '@/components/Auth/AuthWelcome';
import AuthSignUp from '@/components/Auth/AuthSignUp';
import AuthConfirmEmail from '@/components/Auth/AuthConfirmEmail';
import AuthResetPassword from '@/components/Auth/AuthResetPassword';
import AuthResetPasswordRequest from '@/components/Auth/AuthResetPasswordRequest';

export const auth_children = [
	{
		path: 'welcome',
		name: 'AuthWelcome',
		component: AuthWelcome,
	},
	{
		path: 'log-in',
		name: 'AuthLogIn',
		component: AuthLogIn
	},
	{
		path: 'log-out',
		name: 'AuthLogOut',
		component: AuthLogOut
	},
	{
		path: 'support',
		name: 'AuthLogInSupport',
		component: AuthLogIn
	},
	{
		path: 'sign-up',
		name: 'AuthSignUp',
		component: AuthSignUp
	},
	{
		path: 'confirm-email',
		name: 'AuthConfirmEmail',
		component: AuthConfirmEmail
	},
	{
		path: 'reset-password',
		name: 'AuthResetPassword',
		component: AuthResetPassword
	},
	{
		path: 'reset-password-request',
		name: 'AuthResetPasswordRequest',
		component: AuthResetPasswordRequest
	}
];
