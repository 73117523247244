const  AccountSubscriptions  = () => import('@/components/Account/AccountSubscriptions')
const  AccountProfile  = () => import('@/components/Account/AccountProfile')
const  AccountAddress  = () => import('@/components/Account/AccountAddress')
const  AccountBilling  = () => import('@/components/Account/AccountBilling')
const  AccountOrderHistory  = () => import('@/components/Account/AccountOrderHistory')

export const account_children = [
	{
		path: '',
		name: 'Account',
		component: AccountProfile,
	},
	{
		path: 'subscriptions',
		name: 'AccountSubscriptions',
		component: AccountSubscriptions,
	},
	{
		path: 'address',
		name: 'AccountAddress',
		component: AccountAddress,
	},
	{
		path: 'billing',
		name: 'AccountBilling',
		component: AccountBilling,
	},
	{
		path: 'past-orders',
		name: 'AccountOrderHistory',
		component: AccountOrderHistory,
	}
];
