const AdminCoupons = () => import('@/components/Admin/AdminCoupons')
const AdminDistributors = () => import('@/components/Admin/AdminDistributors')
const AdminDistributorsSearch = () => import('@/components/Admin/AdminDistributorsSearch')
const AdminManageMealPlans = () => import('@/components/Admin/AdminManageMealPlans')
const AdminManageMeals = () => import('@/components/Admin/AdminManageMeals')
const AdminMealsUpload = () => import('@/components/Admin/AdminMealsUpload')
const AdminReportDelivery = () => import('@/components/Admin/AdminReportDelivery')
const AdminReportKitchen = () => import('@/components/Admin/AdminReportKitchen')
const AdminReportOrders = () => import('@/components/Admin/AdminReportOrders')
const AdminReportProgress = () => import('@/components/Admin/AdminReportProgress')
const AdminReportRoute = () => import('@/components/Admin/AdminReportRoute')
const AdminReportUserQueries = () => import('@/components/Admin/AdminReportUserQueries')
const AdminReportSubscriptions = () => import('@/components/Admin/AdminReportSubscriptions')
const AdminReportDaily = () => import('@/components/Admin/AdminReportDaily')
const AdminReportCharges = () => import('@/components/Admin/AdminReportCharges')
const AdminStripeAccount = () => import('@/components/Admin/AdminStripeAccount')
const AdminStripeConnect = () => import('@/components/Admin/AdminStripeConnect')
const AdminUserSearch = () => import('@/components/Admin/AdminUserSearch')
const AdminUserDetails = () => import('@/components/Admin/AdminUserDetails')
const AdminSettings = () => import('@/components/Admin/AdminSettings')
const AdminSubscriptions = () => import('@/components/Admin/AdminSubscriptions')
const AdminChargePreview = () => import('@/components/Admin/AdminChargePreview')
const AdminReportNewUser = () => import('@/components/Admin/AdminReportNewUser')
const AdminReportWeeklyOrders = () => import('@/components/Admin/AdminReportWeeklyOrders')
const AdminOrderItemsCSV = () => import('@/components/Admin/AdminOrderItemsCSV')
const AdminDrivers = () => import('@/components/Admin/AdminDrivers')
const AdminNotifications = () => import('@/components/Admin/AdminNotifications')
const AdminManageDeliveryOffsets = () => import('@/components/Admin/AdminManageDeliveryOffsets')
const AdminStateTaxEdit = () => import('@/components/Admin/AdminStateTaxEdit')

import {admin_user_children} from './children/user';

export const admin_children = [
	{
		path: 'customers',
		name: 'AdminUserSearch',
		component: AdminUserSearch
	},
	{
		path: 'new-customers-report',
		name: 'AdminReportNewUser',
		component: AdminReportNewUser
	},
	{
		path: 'customers/:id',
		name: 'AdminUserDetails',
		component: AdminUserDetails,
		children: admin_user_children
	},
	{
		path: 'weekly-orders-report',
		name: 'AdminReportWeeklyOrders',
		component: AdminReportWeeklyOrders,
	},
	{
		path: 'orders-report',
		name: 'AdminOrdersReport',
		component: AdminReportOrders,
	},
	{
		path: 'user-query-report',
		name: 'AdminReportUserQueries',
		component: AdminReportUserQueries,
	},
	{
		path: 'progress-report',
		name: 'AdminReportProgress',
		component: AdminReportProgress,
	},
	{
		path: 'delivery-report',
		name: 'AdminReportDelivery',
		component: AdminReportDelivery,
	},
	{
		path: 'kitchen-report',
		name: 'AdminReportKitchen',
		component: AdminReportKitchen,
	},
	{
		path: 'route-report',
		name: 'AdminReportRoute',
		component: AdminReportRoute,
	},
	{
		path: 'subscriptions-report',
		name: 'AdminReportSubscriptions',
		component: AdminReportSubscriptions,
	},
	{
		path: 'charges-report',
		name: 'AdminReportCharges',
		component: AdminReportCharges,
	},
	{
		path: 'daily-report',
		name: 'AdminReportDaily',
		component: AdminReportDaily,
	},
	{
		path: 'distributors',
		name: 'AdminDistributors',
		component: AdminDistributors,
	},
	{
		path: 'distributors/search',
		name: 'AdminDistributorsSearch',
		component: AdminDistributorsSearch,
	},
	{
		path: 'subscriptions',
		name: 'AdminSubscriptions',
		component: AdminSubscriptions,
	},
	{
		path: 'manage-meals',
		name: 'AdminManageMeals',
		component: AdminManageMeals,
	},
	{
		path: 'manage-meal-plans',
		name: 'AdminManageMealPlans',
		component: AdminManageMealPlans,
	},
	{
		path: 'state-tax',
		name: 'AdminStateTaxEdit',
		component: AdminStateTaxEdit,
	},
	{
		path: 'manage-delivery-offsets',
		name: 'AdminManageDeliveryOffsets',
		component: AdminManageDeliveryOffsets,
	},
	{
		path: 'coupons',
		name: 'AdminCoupons',
		component: AdminCoupons,
	},
	{
		path: 'upload-meal-csv',
		name: 'AdminUploadMealCSV',
		component: AdminMealsUpload,
	},
	{
		path: 'stripe-account',
		name: 'AdminStripeAccount',
		component: AdminStripeAccount,
	},
	{
		path: 'stripe-connect',
		name: 'AdminStripeConnect',
		component: AdminStripeConnect,
	},
	{
		path: 'settings',
		name: 'AdminSettings',
		component: AdminSettings,
	},
	{
		path: 'charge-preview',
		name: 'AdminChargePreview',
		component: AdminChargePreview,
	},
	{
		path: 'drivers',
		name: 'AdminDrivers',
		component: AdminDrivers,
	},
	{
		path: 'order-items-by-week',
		name: 'AdminOrderItemsCSV',
		component: AdminOrderItemsCSV,
	},
	// Printables
	{
		path: 'reports/orders/printable',
		name: 'AdminOrdersReportPrintable',
		component: AdminReportOrders,
	},
	{
		path: 'reports/progress/printable',
		name: 'AdminReportProgressPrintable',
		component: AdminReportProgress,
	},
	{
		path: 'reports/delivery/printable',
		name: 'AdminReportDeliveryPrintable',
		component: AdminReportDelivery,
	},
	{
		path: 'reports/kitchen/printable',
		name: 'AdminReportKitchenPrintable',
		component: AdminReportKitchen,
	},
	{
		path: 'reports/route/printable',
		name: 'AdminReportRoutePrintable',
		component: AdminReportRoute,
	},
	{
		path: 'notifications',
		name: 'AdminNotifications',
		component: AdminNotifications,
	}
];
