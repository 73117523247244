<template>
    <div class="main mt-5">
        <h1>Healthy For Life API Documentation</h1>
        <div class="d-flex flex-row url mt-5">
            <h3>https://dev-api.healthyforlifemeals.com</h3>
            <div class="dropdown">
                <button type="button" class="btn btn-outline-primary dropdown-toggle w-100" data-toggle="dropdown"
                          aria-haspopup="true" aria-expanded="false">
                    {{prefix ? prefix : 'choose a prefix / service'}}
                </button>
                <div class="dropdown-menu w-100">
                    <a class="dropdown-item" v-on:click="set_prefix(prefix)" v-for="(prefix,index) in prefixes" :key='index'>{{prefix}}</a>
                </div>
            </div>
            <div class="dropdown">
                <button type="button" class="btn btn-outline-primary dropdown-toggle w-100" data-toggle="dropdown"
                          aria-haspopup="true" aria-expanded="false">
                    {{path ? path.includes('/') ? path : '/'+path : 'choose a path'}}
                </button>
                <div class="dropdown-menu w-100">
                    <a class="dropdown-item" v-on:click="set_path(path)" v-for="(path,index) in paths" :key='index'>{{path}}</a>
                    <a class="dropdown-item" v-if="!paths">Please Choose a Prefix</a>
                </div>
            </div>
        </div>
        <div class="mt-3">
            <p>Additional Information</p>
            <table class="table" v-if="currentEndpoint">
                <thead>
                <tr>
                    <th scope="col">Method</th>
                    <th scope="col">Path</th>
                    <th scope="col">Service</th>
                    <th scope="col">Function</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(e,index) in currentEndpoint" :key='index'>
                    <td>{{e.method}}</td>
                    <td>{{e.prefix}}{{e.path.includes('/') ? e.path : '/'+e.path}}</td>
                    <td>{{e.service}}</td>
                    <td>{{e.function}}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>

    export default {
        name: 'APIDoc',
        data() {
            return {
                prefixes: null,
                prefix: null,
                paths: null,
                path: null,
                method: 'GET',
                endpoints: null,
                currentEndpoint: null
            };
        },
        methods: {
            set_prefix(prefix) {
                this.prefix = prefix;
                this.paths = this.endpoints.filter(y => {
                    return y.prefix === this.prefix;
                }).map(x => x.path);
                this.path = null;
                if (this.prefix !== null && this.path === null) {
                    this.currentEndpoint = null;
                }
            },
            set_path(path) {
                this.path = path;
            }
        },
        created() {
            this.$store.dispatch('get_endpoints')
            .then(result => {
                this.endpoints = result.endpoints;
                this.prefixes = [... new Set(result.endpoints.map(x => x.prefix))];
            })
        },
        watch: {
            prefix: function () {
            },
            path: function () {
                if (this.prefix !== null && this.path !== null) {
                    this.currentEndpoint = this.endpoints.filter(y => {
                        return y.prefix === this.prefix && y.path === this.path;
                    });
                }
            }
        }
    };
</script>
<style lang="scss">
    @import '../../styles/scss/colors';

    .url {
        h3 {
            border-bottom: 1px solid $purple;
        }
        .dropdown {
            .dropdown-item {
                &:hover {
                    cursor: pointer;
                }
            }
            .dropdown-menu {
                width: 300px !important;
            }
            border-bottom: 1px solid $blue;
            button {
                border: none !important;
                &:hover {
                    background: none;
                    color: $purple;
                }
                &:active {
                    background: none !important;
                    color: $purple !important;
                }
            }
            position: relative;
            top: -8px;
        }
    }

</style>
