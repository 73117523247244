import moment from 'moment';
import md5 from 'md5';

export const token = (state) => {
	return state.user ? state.user.token : null;
};

export const user = (state) => {
	return state.user;
};

export const get_user_addresses = (state) => {
	return state.user && state.user ? state.user.addresses : [];
};

export const get_user_preferences = (state) => {
	return state.user ? state.user.preferences : null;
};

export const get_user_id = (state) => {
	return state.user && state.user.id ? state.user.id : null;
};

export const get_next_pickup_or_delivery = (state) => {
	return state.user && state.user.nextPickupOrDelivery ? state.user.nextPickupOrDelivery : null;
};

export const get_next_customizable_order = (state) => {
	return state.user && state.user.nextCustomizableOrder ? state.user.nextCustomizableOrder : null;
};

export const get_user_weight = (state) => {
	return state.user && state.user.weight ? state.user.weight : null;
};

export const get_user_weights_array = (state) => {
	return state.user && state.user.weights.length > 0 ? state.user.weights : null;
};

export const get_recent_order = (state) => {
	return state.user && state.user.orders ? state.user.mostRecentOrder : null;
};

export const get_recent_order_and_items = (state, getters) => {
	return getters.get_all_orders ? getters.get_all_orders[getters.get_all_orders.length - 1] : null;
};

export const get_all_orders = (state) => {
	return state.user && state.user.orders ? state.user.orders : null;
};

export const get_all_order_items = state => {
	if (state.user && state.user.orders) {
		return state.user.orders.reduce((acc, o) => acc.concat(o.orderItems), [])
						.filter(oi => oi.stage === 'editable')
						.sort((a, b) => a.deliveryDate > b.deliveryDate);
	}
	return [];
};

export const get_upcoming_order_items = state => {
	if (state.user && state.user.orders) {
		return state.user.orders.reduce((acc, o) => acc.concat(o.orderItems), [])
						.filter(oi => moment(oi.deliveryDate).isAfter(moment()) && oi.stage === 'editable');
		// .sort((a, b) => a.deliverydate > b.deliverydate)
		//so sorting was actually unsorting?
	}
	return [];
};

export const get_user_avatar_url = (state) => {
	// TODO: Check if user has an imageURL set, otherwise default to Gravatar
	if (state.user == null || state.user.email == null) {
		return '';
	}
	return '//www.gravatar.com/avatar/' + md5(state.user.email.trim().toLowerCase()) + '?s=200&d=identicon';
};

export const get_user_subscriptions = state => {
	return state.user && state.user.subscriptions && state.user.subscriptions.length > 0 ? state.user.subscriptions : [];
};
