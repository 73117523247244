import Vue from 'vue';
import Vuex from 'vuex';
//** ACTIONS **//
import * as auth_actions from './auth/actions.js';
import * as global_actions from './ui/actions.js';
import * as admin_actions from './admin/actions.js';
import * as order_actions from './orders/actions.js';
import * as payment_actions from './payments/actions';
import * as distributors_actions from './distributors/actions';
import * as user_actions from './user/actions';
import * as meal_actions from './meals/actions';
import * as notifications_actions from './notifications/actions';
//** MUTATIONS **//
import * as auth_mutations from './auth/mutations.js';
import * as global_mutations from './ui/mutations.js';
import * as admin_mutations from './admin/mutations.js';
// import * as order_mutations from './orders/mutations.js';
import * as payment_mutations from './payments/mutations';
import * as distributors_mutations from './distributors/mutations';
import * as user_mutations from './user/mutations';
import * as meal_mutations from './meals/mutations';
import * as notifications_mutations from './notifications/mutations';
//** GETTERS **//
import * as admin_getters from './admin/getters.js';
import * as user_getters from './user/getters';
import * as distributors_getters from './distributors/getters';
import * as globals_getters from './ui/getters';
import * as order_getters from './orders/getters';
import * as payment_getters from './payments/getters';
import * as meal_getters from './meals/getters';
import * as notifications_getters from './notifications/getters';
//** STATEs **//
import {admin_state} from './admin/state';
import {distributors_state} from './distributors/state';
import {payment_state} from './payments/state';
import {meals_state} from './meals/state';
import {ui_state} from './ui/state';
import {user_state} from './user/state';
import {notifications_state} from './notifications/state';

Vue.use(Vuex);

export const store = new Vuex.Store({
	state: {
		order: null,
		receipt: null,
		...ui_state,
		...admin_state,
		...distributors_state,
		...payment_state,
		...meals_state,
		...user_state,
		...notifications_state,
	},
	actions: {
		...admin_actions,
		...auth_actions,
		...distributors_actions,
		...global_actions,
		...meal_actions,
		...order_actions,
		...payment_actions,
		...user_actions,
		...notifications_actions,
	},
	mutations: {
		...admin_mutations,
		...auth_mutations,
		...distributors_mutations,
		...global_mutations,
		...meal_mutations,
		// ...order_mutations,
		...payment_mutations,
		...user_mutations,
		...notifications_mutations,
	},
	getters: {
		...admin_getters,
		...distributors_getters,
		...globals_getters,
		...meal_getters,
		...order_getters,
		...payment_getters,
		...user_getters,
		...notifications_getters
	}
});

//DOCUMENTATION//
//*****************************************************~~ACTIONS~~***********************************************************************************//
//***************************************************************************************************************************************************//
//  Actions are responsible for asynchronous operations. Actions are "dispatched" via this.$store.dispatch('action_name', ?payload).                 //
//  Actions commit "mutations" (i.e., they modify the state object in some way), by calling the "commit" function which vuex passes into the action. //
//  Actions can only take one parameter as an argument: to bypass this restriction, we pass a "payload" object, which we destructure inside the      //
//  function with es6 destructuring: given a payload object => const {arg, arg', arg''} = payload.                                                   //
//***************************************************************************************************************************************************//

//*****************************************************~~MUTATIONS~~*********************************************************************************//
//***************************************************************************************************************************************************//
//  Mutations are responsible for modifying the state. Mutations are "committed" via this.$store.commit('mutation_name', ?payload).                  //
//  Mutations are ALWAYS synchronous                                                                                                                 //
//  commit('mutation_name')                                                                                                                          //
//***************************************************************************************************************************************************//

//*****************************************************~~GETTERS~~***********************************************************************************//
//***************************************************************************************************************************************************//
//  Getters are responsible for deriving computed data from our state tree.                                                                          //
//  Getters are how we intend to cut down on lambdas/REST-endpoints. We derive our own computed state with Getters to avoid unneccessary http calls  //
//  Getters can return functions, in orders to allow parameters. getter_name = (state) => (param) => { function_body }                                //
//***************************************************************************************************************************************************//
