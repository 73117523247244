import * as Api from '../api.js';

export const get_notifications = ({commit, getters}) => {
	return Api.get({
		commit: commit,
		endpoint: `users/notifications`,
		token: getters.token
	}).then(result => {
		if (result.error) {
			// TODO: Handle Error
		} else {
			commit('set_notifications', result.notifications);
		}
		return result;
	});
};

export const save_notification = ({commit, getters}, notification) => {
	return Api.update({
		commit: commit,
		endpoint: `users/notification/${notification.id}`,
		token: getters.token,
		body: notification
	}).then(result => {
		if (result.error) {
			// TODO: Handle Error
		} else {
			commit('set_notification', notification);
		}
		return result;
	}).catch( () => {
		// todo: handle error
	});
};

export const delete_notification = ({commit, getters}, notification) => {
	return Api.destroy({
		commit: commit,
		endpoint: `users/notification/${notification.id}`,
		token: getters.token,
	}).then(result => {
		if (result.error) {
			// TODO: Handle Error
		} else {
			commit('delete_notification', notification);
		}
		return result;
	}).catch( () => {
		// todo: handle error
	});
};
