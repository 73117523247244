export const get_cutoffs = (state) => {
	return state.payment.cutoffs;
};

export const get_order = state => {
	if (state.order.mealPlan === null) {
		state.order = JSON.parse(sessionStorage.getItem('order')) || {};
	}
	return state.order;
};

