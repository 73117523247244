import moment from 'moment';

// export const orders_by_distributor = (state) => {
//   let distributor_orders = [...new Set(state.distributor_orders.map(x => x.distributorId))]
//   let mapped_orders = distributor_orders.map(x => {
//     return state.distributor_orders.filter(y => {
//       return y.distributorId === x
//     })
//   })
//   return mapped_orders
// }

export const get_admin_distributors = (state) => {
	return JSON.parse(state.user.customClaims).distributors;
};

export const get_admin_paths = (state) => {
	return state.admin.adminPaths;
};

export const get_distributor_orders = (state) => {
	return state.distributor_orders;
};

export const get_nonce = () => {
	return sessionStorage.getItem('nonce');
};

export const get_user_search_results = (state) => {
	return state.admin.userSearchResults;
};

export const get_admin_current_user = (state) => {
	return state.admin.currentUser;
};

export const get_distributors_managed = (state) => {
	return state.distributors.all.filter(distributor => state.admin.distributor_ids.includes(distributor.id))
	.sort((a, b) => a.name.localeCompare(b.name));
};

export const get_distributor_routes = (state) => {
	return [...new Set(state.distributors.all.map(d => d.routeName))].sort();
};

export const get_next_delivery_day = () => {
	let now = moment();
	if (now.day() > 1 && now.day() < 5) {
		now.day(4);
	} else {
		now.day(1 + 7);
	}
	return now.toDate();
};

export const get_admin_coupons = (state) => {
	return state.admin.coupons || [];
};

export const is_driver = (state) => {
	if (state.user) {
		let roles = JSON.parse(state.user.roles);
		return roles.includes('driver');
	}
	return false;
};

export const is_agent = (state) => {
	if (state.user) {
		let roles = JSON.parse(state.user.roles);
		return roles.includes('superAdmin') || roles.includes('admin') || roles.includes('agent');
	}
	return false;
};

export const is_finance = (state) => {
	if (state.user) {
		let roles = JSON.parse(state.user.roles);
		return roles.includes('finance');
	}
	return false;
};

export const is_admin = (state) => {
	if (state.user) {
		let roles = JSON.parse(state.user.roles);
		return roles.includes('superAdmin') || roles.includes('admin');
	}
	return false;
};

export const is_super_admin = (state) => {
	if (state.user) {
		let roles = JSON.parse(state.user.roles);
		return roles.includes('superAdmin');
	}
	return false;
};

export const check_order_dates_admin = (state, getters) => {
	if (getters.get_admin_current_user && getters.get_admin_current_user.orders) {
		let dates = getters.get_admin_current_user.orders
		.map(x => x.orderItems)
		.reduce((a, b) => a.concat(b), [])
		.map(x => x.deliveryDate)
		.map(x => moment(x).format('MMMM DD YYYY'));
		return dates;
	}
};

export const get_order_totals = state => {
	return state.admin.orderTotals;
};

export const get_order_totals_user_data = state => {
	return state.admin.orderTotalsUserData;
};

export const get_range = state => {
	return state.admin.range.start && state.admin.range.end ? {
		start: state.admin.range.start,
		end: state.admin.range.end
	} : null;
};
