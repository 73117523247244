export const set_view_label = (state, view) => {
	state.ui.current_view = view;
};
export const set_admin_view = (state, view) => {
	state.ui.admin_view = view;
};

export const set_user_search_results = (state, users) => {
	state.admin.userSearchResults = users || [];
};

export const set_distributor_orders = (state, orders) => {
	state.distributor_orders = orders;
};

export const set_nonce = (state, nonce) => {
	sessionStorage.setItem('nonce', nonce);
};

export const set_admin_current_user = (state, user) => {
	state.admin.currentUser = user;
};

export const clear_admin_current_user = (state) => {
	state.admin.currentUser = null;
};

export const set_admin_distributor_ids = (state, distributor_ids) => {
	state.admin.distributor_ids = distributor_ids;
};

export const set_admin_coupons = (state, coupons) => {
	state.admin.coupons = (coupons || []).sort((a, b) => {
		return a.code > b.code;
	});
};

export const set_admin_coupon = (state, coupon) => {
	// remove existing coupon
	let trimmed = state.admin.coupons.filter(element => element.id !== coupon.id);
	// add the new (updated) element
	trimmed.push(coupon);
	// re-sort!
	trimmed.sort((a, b) => {
		return a.code > b.code;
	});
	state.admin.coupons = trimmed;
};

export const delete_admin_coupon = (state, coupon) => {
	// remove coupon
	state.admin.coupons = state.admin.coupons.filter(element => element.id !== coupon.id);
};

export const set_totals = (state, totals) => {
	state.admin.orderTotals = totals;
};

export const set_totals_user_data = (state, totals) => {
	state.admin.orderTotalsUserData = totals;
};

export const set_range = (state, {start, end}) => {
	state.admin.range.start = start;
	state.admin.range.end = end;
};
