<template>
	<div class="container-fluid">
		<div class="row">


			<div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">

				<div class="row my-0 my-md-5 justify-content-center">
					<div class="col-11">
						<a href="https://www.healthyforlifemeals.com">
							<img class="hflm-logo" src="/static/HFLM_Logo_Horizontal.png"/>
						</a>
					</div>
				</div>

				<transition name="bounce" mode="out-in">
					<router-view/>
				</transition>

			</div>
			<div class="d-none d-md-block col-xl-6 col-lg-6 col-md-6">
				<video playsinline autoplay muted loop poster="/static/img.png" id="bgvid" class="video-container">
					<source src="/static/veggie.mp4" type="video/mp4">
				</video>
			</div>
		</div>

		<div class="row position-fixed w-100 footer">
			<div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 text-center footer-inner p-2">
				© 2024 Healthy For Life Meals
			</div>
		</div>
	</div>
</template>

<script>
	const $ = window.$

	export default {
		components: {},
		name: 'auth',
		methods: {
			back_from_reset() {
				this.log_user_in = true;
				this.sign_user_up = false;
				this.reset_user_password = false;
			},
			resetPassword() {
				this.reset_loader = true;
				this.$store.dispatch('reset_password_request', {email: this.email})
				.then( () => {
					this.reset_loader = false;
					$('#instructions').hide();
					$('#inputForm').hide();
					$('#resetSentText').show();
					setTimeout(() => {
						this.log_user_in = true;
						this.sign_user_up = false;
						this.reset_user_password = false;
					}, 5000);
				})
				.catch( () => {
					this.reset_loader = false;
				});
			},
			validateBeforeSubmit() {
				this.$validator.validateAll()
				.then(result => {
					if (result) {
						this.resetPassword();
					}
				});
			},
			toggle_password_reset() {
				if (this.log_user_in === true) {
					this.log_user_in = false;
				}
				this.reset_user_password = !this.reset_user_password;
			},
			finished() {
				this.$router.push('/dashboard');
			},
			toggle_sign_user_up() {
				if (this.log_user_in === true) {
					this.log_user_in = false;
				}
				this.sign_user_up = !this.sign_user_up;
			},
			toggle_log_user_in() {
				if (this.sign_user_up === true) {
					this.sign_user_up = false;
				}
				this.log_user_in = !this.log_user_in;
			},
			log_in_finished(user) {
				if (this.$route.name === 'LogInSupport') {
					let route = 'https://support.healthyforlifemeals.com/access/jwt?jwt=' + user.supportToken;
					let return_to = this.$route.query.return_to;
					if (return_to != null && return_to.length > 0) {
						route += '&return_to=' + return_to;
					}
					window.location.href = route;
				} else {
					this.$router.push('/dashboard');
				}
			},
			log_in() {
				if (this.credentials.email.length < 4) {
					$('#email').addClass('is-danger');
					setTimeout(() => {
						$('#email').removeClass('is-danger');
					}, 2500);
				}
				if (this.credentials.password.length < 8) {
					$('#password').addClass('is-danger');
					setTimeout(() => {
						$('#password').removeClass('is-danger');
					}, 2500);
				} else {
					this.$store.dispatch('log_in', this.credentials)
					.then(response => {
						if (response.error) {
							this.error = response.error;
							setTimeout(() => {
								this.error = null;
							}, 5000);
						} else {
							this.log_in_finished(response.user);
						}
					})
					.catch(error => {
						this.error = error.message;
						setTimeout(() => {
							this.error = null;
						}, 5000);
					});
				}
			}
		}
	};
</script>

<style scoped lang="scss">
	@import '../../styles/scss/colors';

	.video-container {
		position: absolute;
		height: 100vh !important;
	}

	.hflm-logo {
		height: 100px;
	}

	.input-group-text {
		width: 40px;
	}

	.icon {
		color: $grey;
	}

	/* Change the white to any color ;) */
	input:-webkit-autofill {
		-webkit-box-shadow: 0 0 0 30px white inset;
	}

	.footer {
		bottom: 0;
		.div {
		}
		.footer-inner {
			background-color: white;
			border-top: 1px solid $silver;

		}
	}

	.sign-up {
		color: $purple !important;
		font-weight: 600;
		&:hover {
			cursor: pointer;
		}
	}

	.forgot {
		font-weight: bold;
		color: $purple;
	}

	#resetSentText {
		display: none;
	}
</style>
