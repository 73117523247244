import { render, staticRenderFns } from "./SubNav.vue?vue&type=template&id=85034ada&scoped=true"
import script from "./SubNav.vue?vue&type=script&lang=js"
export * from "./SubNav.vue?vue&type=script&lang=js"
import style0 from "./SubNav.vue?vue&type=style&index=0&id=85034ada&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "85034ada",
  null
  
)

export default component.exports