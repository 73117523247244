import * as Api from '../api';

export const get_distributors = ({commit, getters}) => {
	if (getters.get_distributors.length > 0) {
		return new Promise((resolve) => {
			resolve();
		});
	}

	return Api.get({
		commit: commit,
		endpoint: `distributors`,
		token: getters.token
	})
	.then(result => {
		if (result.error) {

			//  todo: handle error
		} else {
			commit('set_distributors', result.distributors);
		}
		return result;
	})
	.catch(() => {

		//  todo: handle error
	});
};

export const get_distributor = ({commit, getters}, {dist_id}) => {
	return Api.get({
		commit: commit,
		endpoint: `distributors/${dist_id}`,
		token: getters.token
	})
	.then(result => {
		if (result.error) {

			//  todo: handle error
		} else {
			commit('set_distributor', result.distributor);
		}
		return result;
	})
	.catch(() => {

		//  todo: handle error
	});
};
export const get_distributor_extras = ({commit, getters}, {distributorId, deliveryDate}) => {
	return Api.get({
		commit: commit,
		endpoint: `distributors/${distributorId}/extras/${deliveryDate}`,
		token: getters.token
	})
	.then(result => {
		return result;
	})
	.catch( () => {
		//  todo: handle error
	});
};

export const get_distributor_for_zip = ({commit}, {zip, pickup}) => {
	return Api.get({
		commit: commit,
		endpoint: `distributors/find/${zip}?pickup=${pickup}`
	})
	.then(result => {
		if (result.error) {
			// handle error
		}
		return result;
	})
	.catch( () => {

		//  todo: handle error
	});
};

export const add_zip_to_distributor = ({commit, getters}, {id, zips}) => {
	return Api.post({
		commit: commit,
		endpoint: `distributors/${id}/zip`,
		token: getters.token,
		body: zips
	})
	.then(result => {
		if (result.error) {
			// TODO: Handle Error
			console.log("Error from adding zip", result.error)
		} 
		return result;
	})
	.catch(error => {
		console.log("catch from adding zip", error)
	});
};

export const remove_zip_from_distributor = ({commit, getters}, {id, zips}) => {
	return Api.destroy({
		commit: commit,
		endpoint: `distributors/${id}/zip`,
		token: getters.token,
		body: zips
	})
	.then(result => {
		if (result.error) {
			// TODO: Handle Error
			console.log("Error from adding zip", result.error)
		} 
		return result;
	})
	.catch(error => {
		console.log("catch from adding zip", error)
	});
};


export const get_tax_rates = ({commit, getters}) => {
	return Api.get({
		commit: commit,
		endpoint: `distributors/taxRates`,
		token: getters.token
	}).then(result => {
		if (result.error) {
			// TODO: Handle Error
		} else {
			commit('set_tax_rates', result.rates);
		}
		return result;
	})
	.catch(() => {

	});
};

export const save_tax_rates = ({commit, getters}, {stateTaxRates}) => {
	return Api.post({
		commit: commit,
		endpoint: `distributors/taxRates`,
		token: getters.token,
		body: stateTaxRates
	})
	.then(result => {
		if (result.error) {
			// TODO: Handle Error
		} else {
			//commit('save_meal_plan', result.mealPlan)
		}
		return result;
	})
	.catch(() => {

	});
};
