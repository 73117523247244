<template>
    <div>
        <form v-on:submit.prevent="validateBeforeSubmit">


            <div class="row justify-content-center px-lg-5">
                <div class="col-10 text-center">
                    <h1>Reset My Password</h1>
                </div>
            </div>

            <div class="row mt-3 justify-content-center px-lg-5">
                <div class="col-10 text-center" v-if="!showNote">
                    <p>Looks like you are having problems logging in.<br/>
                        Please enter your e-mail below to begin the password reset process.</p>
                </div>
                <div class="col-10 text-center font-weight-bold" v-else>
                    <p class="font-weight-bold text-primary">Check your email for further instructions.</p>
                </div>
            </div>

            <div class="row mt-3 justify-content-center px-lg-5">
                <div class="col-10 input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text"><icon fill="currentColor" name="envelope" class="icon"/></span>
                    </div>
                    <input class="form-control"
                             type="text"
                             v-model="email"
                             name="email"
                             v-validate="{'required': true, 'email': true}"
                             :class="{'input': true, 'is-danger': errors.has('E-Mail')}"
                             :disabled="loading"/>
                </div>
            </div>

            <div class="row mt-3 justify-content-center px-lg-5">
                <div class="col-10">
                    <button class="btn btn-primary w-100" :disabled="loading">
                        {{loading ? 'Resetting Password' : 'Reset My Password'}}
                        <icon fill="currentColor" name="spinner" spin class="loading-icon ml-1" v-if="loading"/>
                    </button>
                </div>
            </div>

            <div class="row mt-5 justify-content-center px-lg-5">
                <div class="col-4 text-left">
                    <router-link class="" to="log-in">Log In</router-link>
                </div>
                <div class="col-2"></div>
                <div class="col-4 text-right">
                    <router-link class="" to="sign-up">Sign Up</router-link>
                </div>
            </div>


        </form>
    </div>
</template>

<script>
    export default {
        name: 'auth-reset-password-request',
        data() {
            return {
                loading: false,
                showNote: false,
                email: null
            };
        },
        methods: {
            resetPassword() {
                this.loading = true;
                this.$store.dispatch('reset_password_request', {email: this.email})
                .then( () => {
                    this.email = '';
                    this.loading = false;
                    this.showNote = true;
                });
            },
            validateBeforeSubmit() {
                this.$validator.validateAll()
                .then(result => {
                    if (result) {
                        this.resetPassword();
                    }
                });
            }
        }
    };
</script>
