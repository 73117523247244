<template>
	<div class="main">
		<h1>Not Found</h1>
		<p>We couldn't find this page for you...</p>
	</div>
</template>

<script>
	export default {
		data() {
			return {};
		},
		mounted() {
		}
	};
</script>

<style lang="scss" scoped>
</style>
