<template>
    <div>
        <div>
            <div class="sidebar">
                <h1>{{title}}</h1>
                <div class="main-sidebar">
                    <router-link v-for="(path,i) in navPaths" :key="i" :to="path.path" 
                                 :class="`${path.style}-path`" 
                                 class="sidebar-item ml-2"
                    >
                        {{path.name}}
                    </router-link>
                </div>
                <div>
                    <span class="version" v-if="version">Version - {{version.split(' ')[0]}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    const $ = window.$    
    
    export default {
        name: 'main-sidebar',
        props: ['paths', 'title', 'version'],
        mounted() {
            let top = this.$route.path.split('/').filter(x => x !== '');
            if (top.length === 1) {
                $(`.sidebar-item.${this.paths[0].style}-path`).addClass('clicked');
            } else {
                $(`.sidebar-item.${top[1]}-path`).addClass('clicked');
            }
        },
        computed: {
            navPaths() {
                return this.paths.filter( path => {return path.check ? path.check._if : true ;})
            }
        },
        watch: {
            '$route': function () {
                this.$nextTick(function () {
                    let top = this.$route.path.split('/').filter(x => x !== '');
                    if (top.length === 1) {
                        $(`.sidebar-item.${this.paths[0].style}-path`).addClass('clicked');
                        $(`.sidebar-item.${top[0]}-path`).addClass('clicked');
                    } else {
                        $(`.sidebar-item.${top[1]}-path`).addClass('clicked');
                    }
                });
            }
        }
    };
</script>

<style scoped lang="scss">
    @import '../../styles/scss/colors';

    .version {
        font-size: 12px;
        color: $purple;
        margin-left: 20px;
        font-style: italic;
    }

    .main-sidebar {
        border-left: 1px solid $silver;
    }

    .sidebar-item {
        list-style: none;
        text-decoration: none;
        color: $grey;
        display: block;
        padding: .375rem .75rem;
        width: 80%;
        &.clicked {
            color: $purple;
            background: $light-silver;
            box-shadow: 0 4px 2px -2px $purple;
            display: block;
            font-weight: 600;
            white-space: nowrap;
            vertical-align: middle;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            padding: .375rem .75rem;
            line-height: 1.5;
            font-style: italic;
            border-radius: .25rem;
            transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
            width: 80%;
            text-align: left;
        }
    }
</style>
